import { ProgressBar } from "primereact/progressbar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {};

const Maintenance = (props: Props) => {
    const { t } = useTranslation();
    const [randomTexts, setRandomTexts] = useState([
        "HTML-Seiten entworfen",
        "Funktionen geschrieben",
        "Tickets übertragen",
        "Backups erstellt",
        "Backups geprüft",
        "Bugs behoben",
        "Meetings gehalten",
        "Entwürfe diskutiert",
    ]);
    const [randomText, setRandomText] = useState("");

    useEffect(() => {
        getRandomText();

        const interval = setInterval(() => {
            getRandomText();
        }, 10000);
    }, []);

    const getRandomText = () => {
        setRandomText(
            randomTexts[Math.floor(Math.random() * randomTexts.length)]
        );
    };

    return (
        <div className="flex flex-wrap">
            <div
                className="w-full md:w-6 px-4 py-8 md:px-6 lg:px-8"
                style={{
                    background:
                        "linear-gradient(0deg, rgba(82, 82, 82, 0.42), rgba(160, 200, 88, 0.42)), linear-gradient(246.36deg, #525252 15.22%, #a0c858 65.22%)",
                }}
            >
                <div className="text-4xl text-white mb-3 font-medium">
                    Coming soon
                </div>
                <p className="line-height-3 mt-0 mb-7 p-0 text-white text-2xl">
                    Zur Zeit erstellen wir unser neues Supportportal
                </p>
                <ProgressBar
                    mode="indeterminate"
                    style={{ height: "6px" }}
                    color="#525252"
                ></ProgressBar>
                <h2 className="shine">Es werden: {randomText}</h2>
                <div className="text-4xl text-white mb-3 font-medium">
                    Wir stehen Ihnen wie gewohnt unter der Mailadresse:{" "}
                    <a
                        className="text-white"
                        href="mailto:service@moso-connect.de"
                    >
                        service@moso-connect.de
                    </a>{" "}
                    zur Verfügung
                </div>
            </div>
            <div
                className="w-full md:w-6 bg-no-repeat bg-cover"
                style={{
                    background:
                        "url('https://www.modernsolution.net/media/image/opc/lg/all_in_one_3.jpg')",
                    minHeight: "500px",
                }}
            ></div>
        </div>
    );
};

export default Maintenance;
