import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

try {
    let token = localStorage.getItem("token");
    if (token) {
        axios.defaults.headers.common.Authorization = token;
    }
} catch (err) {}

export const login = async (cEmail: string, password: string, cSecurityCode: string) => {
    let { data, status } = await axios.post("/user/login", {
        cEmail: cEmail,
        passwort: password,
        cSecurityCode: cSecurityCode
    });
    return { data, status };
};

export const sentCode = async (cEmail: string, password: string) => {
    let { data, status } = await axios.post("/user/sentcode", {
        cEmail: cEmail,
        passwort: password,
    });
    return { data, status };
};