import { Paginator } from "primereact/paginator";
import { Slider } from "primereact/slider";
import { Tooltip } from "primereact/tooltip";

type Props = {
    paginatorCount: number;
    paginatorFirst: number;
    paginatorRows: number;
    onPageChange: (event: any) => void;
};

const TicketListPaginator = ({
    paginatorCount,
    paginatorFirst,
    paginatorRows,
    onPageChange,
}: Props) => {
    const paginatorTemplate: any = {
        layout: "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
        RowsPerPageDropdown: (options: any) => {
            return (
                <div className="flex align-items-center">
                    <Tooltip
                        target=".slider>.p-slider-handle"
                        content={`${options.value} / pro Seite`}
                        position="top"
                        event="focus"
                    />

                    <span
                        className="mr-3"
                        style={{
                            color: "var(--text-color)",
                            userSelect: "none",
                        }}
                    >
                        Elemente pro Seite:{" "}
                    </span>
                    <Slider
                        className="slider"
                        value={options.value}
                        onChange={options.onChange}
                        min={12}
                        max={48 > paginatorCount ? paginatorCount : 48}
                        step={4}
                        style={{ width: "10rem" }}
                    />
                </div>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} von {options.totalRecords}
                </span>
            );
        },
    };
    return (
        <Paginator
            template={paginatorTemplate}
            first={paginatorFirst}
            rows={paginatorRows}
            totalRecords={paginatorCount}
            onPageChange={onPageChange}
        />
    );
};

export default TicketListPaginator;
