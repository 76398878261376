import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Ticket } from "../logik/interfaces";
import { useEffect, useRef, useState } from "react";
import TicketHistory from "./ticketContent/TicketHistory";
import { Sidebar } from "primereact/sidebar";
import TicketAnswering from "./ticketContent/TicketAnswering";
import { Toast } from "primereact/toast";

import TicketAssignment from "./ticketContent/TicketAssignment";
import { Dialog } from "primereact/dialog";
import TicketEditSubject from "./ticketContent/TicketEditSubject";
import { getTicketsByTicketID } from "../logik/mysqlapicalls";
import Logo from "../../general/Logo";
import TicketBilling from "./TicketBilling";

type Props = {
    kTicket: number;
    isDialog: boolean;
    setReloadParent: (reload: boolean) => void;
    setCurrentTicket: (ticket: any) => void;
};

const TicketContent = ({
    kTicket,
    isDialog,
    setReloadParent,
    setCurrentTicket,
}: Props) => {
    const toast = useRef(null);
    const [
        storageTicketsystemPopupWidth,
        setStorageTicketsystemPopupWidth,
    ]: any = useState(
        window.electron.store.get("ticketsystem.popup.width")
            ? window.electron.store.get("ticketsystem.popup.width")
            : "50vw"
    );
    const [
        storageTicketsystemPopupHeight,
        setStorageTicketsystemPopupHeight,
    ]: any = useState(
        window.electron.store.get("ticketsystem.popup.height")
            ? window.electron.store.get("ticketsystem.popup.height")
            : "100vh"
    );
    const [menuItems, setMenuItems]: any = useState([]);
    const [displayTicket, setDisplayTicket] = useState(false);
    const [mainContentIndex, setMainContentIndex]: any = useState(0);
    const [visibleSidebar, setVisibleSidebar] = useState(false);

    const [sidebarIndex, setSidebarIndex]: any = useState(0);
    const [filter, setFilter]: any = useState("");
    const [note, setNote] = useState("");
    const [answer, setAnswer] = useState("");

    const [reload, setReload] = useState(false);
    const [reloadHistory, setReloadHistory] = useState(false);

    //@ts-ignore
    const [ticket, setTicket]: Ticket = useState(null);

    useEffect(() => {
        setMenuItems(
            process.env.IS_ELECTRON
                ? [
                      {
                          label: "Antworten",
                          icon: "pi pi-fw pi-comments",
                          command: () => {
                              setSidebarIndex(1);
                              setVisibleSidebar(true);
                          },
                      },
                      {
                          label: "Notiz",
                          icon: "pi pi-fw pi-book",
                          command: () => {
                              setSidebarIndex(0);
                              setVisibleSidebar(true);
                          },
                      },
                      {
                          label: "Historie",
                          icon: "pi pi-fw pi-list",
                          command: () => {
                              setMainContentIndex(0);
                          },
                      },
                      {
                          label: "Zeitbuchung",
                          icon: "pi pi-fw pi-euro",
                          command: () => {
                              setMainContentIndex(1);
                          },
                      },
                      {
                          label: "Refresh",
                          icon: "pi pi-fw pi-sync",
                          command: () => {
                              setReload(true);
                          },
                      },
                  ]
                : [
                      {
                          label: "Antworten",
                          icon: "pi pi-fw pi-comments",
                          command: () => {
                              setSidebarIndex(1);
                              setVisibleSidebar(true);
                          },
                      },
                      {
                          label: "Refresh",
                          icon: "pi pi-fw pi-sync",
                          command: () => {
                              setReload(true);
                          },
                      },
                  ]
        );
    }, []);

    useEffect(() => {
        if (kTicket && isDialog) {
            setDisplayTicket(true);
        }
        if (kTicket) {
            getAPITicket();
        } else {
            setTicket(null);
        }
    }, [kTicket]);

    useEffect(() => {
        if (reload) {
            setFilter("");
            getAPITicket();
        }
    }, [reload]);

    const getAPITicket = async () => {
        let ticket = await getTicketsByTicketID(kTicket);
        await setTicket(ticket[0]);
        setReloadHistory(true);
        setReload(false);
    };

    const getSidebarContent = () => {
        return (
            <TicketAnswering
                ticket={ticket}
                isNote={sidebarIndex === 0 ? true : false}
                note={note}
                answer={answer}
                draftText={draftText}
                toggleSidebar={toggleSidebar}
                showToast={showToast}
            />
        );
    };

    const getMainContent = () => {
        return (
            <div className="bg-white border-round-xs p-5">
                {isDialog ? (
                    <></>
                ) : (
                    <h3 className="mb-5">
                        <TicketEditSubject ticket={ticket} />
                    </h3>
                )}
                <Menubar
                    model={menuItems}
                    start={<Logo width="150px" className="" />}
                    end={
                        <InputText
                            placeholder="Verlauf durchsuchen"
                            type="text"
                            value={filter}
                            onChange={(e: any) => {
                                setFilter(e.target.value);
                            }}
                        />
                    }
                />
                {process.env.IS_ELECTRON && (
                    <TicketAssignment ticket={ticket} />
                )}
                {mainContentIndex === 0 ? (
                    <TicketHistory
                        ticket={ticket}
                        filter={filter}
                        reload={reloadHistory}
                        finishReload={setReloadHistory}
                        reloadParent={reloadContent}
                    />
                ) : mainContentIndex === 1 && process.env.IS_ELECTRON ? (
                    <TicketBilling
                        ticketID={null}
                        ticketState={null}
                        ticketTopic={null}
                        openingDate={null}
                        messages={null}
                        jumpToTicket={false}
                        setCurrentTicket={() => {}}
                    />
                ) : (
                    <></>
                )}
            </div>
        );
    };

    const getTicketHeader = () => {
        return <TicketEditSubject ticket={ticket} />;
    };

    const draftText = (text: string, isNote: boolean) => {
        if (isNote) {
            setNote(text);
        } else {
            setAnswer(text);
        }
    };

    const toggleSidebar = (show: boolean) => {
        setVisibleSidebar(show);
    };

    const reloadContent = () => {
        setReload(true);
    };

    const showToast = (
        severity: string,
        summary: string,
        detail: string,
        life: number
    ) => {
        //@ts-ignore
        toast.current.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: life,
        });
    };

    return (
        <>
            {isDialog ? (
                <Dialog
                    header={getTicketHeader}
                    visible={displayTicket}
                    draggable={false}
                    maximizable
                    modal
                    style={{
                        width: storageTicketsystemPopupWidth,
                        height: storageTicketsystemPopupHeight,
                    }}
                    position="top"
                    onHide={() => {
                        setReloadParent(true);
                        setDisplayTicket(false);
                        setCurrentTicket(null);
                    }}
                    onResizeEnd={(e: any) => {
                        setStorageTicketsystemPopupWidth(
                            `${e.target.firstChild.clientWidth}px`
                        );
                        window.electron.store.set(
                            "ticketsystem.popup.width",
                            `${e.target.firstChild.clientWidth}px`
                        );
                        setStorageTicketsystemPopupHeight(
                            `${e.target.firstChild.clientHeight}px`
                        );
                        window.electron.store.set(
                            "ticketsystem.popup.height",
                            `${e.target.firstChild.clientHeight}px`
                        );
                    }}
                >
                    {getMainContent()}
                </Dialog>
            ) : (
                getMainContent()
            )}
            <Sidebar
                visible={visibleSidebar}
                modal={false}
                position="bottom"
                onHide={() => {
                    setVisibleSidebar(false);
                }}
                style={{ minHeight: "25vw", minWidth: "16.6667%" }}
            >
                {getSidebarContent()}
            </Sidebar>
            <Toast ref={toast} position="bottom-right" />
        </>
    );
};

export default TicketContent;
