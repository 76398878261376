import { t } from "i18next";
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    NavLink,
} from "react-router-dom";
import "./App.css";
import Login from "./components/general/Login";
import Layout from "./components/Layout/Layout";
import TicketList from "./components/ticket/pages/TicketList";
import { useEffect, useState } from "react";
import Maintenance from "./components/general/Maintenance";
import { isTokenExpired } from "./logik/utils";
import axios from "axios";
import TicketCreate from "./components/ticket/pages/TicketCreate";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [login, setLogin] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        let token = localStorage.getItem("token");
        setAuthenticated(false);

        if (token) {
            if (isTokenExpired(token)) {
                localStorage.removeItem("token");
            } else {
                setAuthenticated(true);
                axios.defaults.headers.common.Authorization = token;

                setToHappen(() => {
                    console.log("Set USRE NULL");
                    localStorage.removeItem("token");
                    setAuthenticated(false);
                }, new Date(JSON.parse(atob(token.split(".")[1])).exp * 1000));
            }
        } else {
            navigate("/login");
            setAuthenticated(false);
        }
    }, []);

    const setToHappen = (fn: any, date: any) => {
        return setTimeout(fn, date - Date.now());
    };

    const routes = [
        {
            path: "/login",
            name: "Login",
            component: (
                <Login
                    onLogin={() => {
                        setLogin(true);
                        setAuthenticated(true);
                    }}
                />
            ),
            needsAuthentication: false,
            breadcrump: [],
        },
        {
            path: "/maintenance",
            name: "Maintenance",
            component: <Maintenance />,
            needsAuthentication: false,
            breadcrump: [],
        },
        {
            path: "/tickets",
            name: t("menu.tickets"),
            component: (
                <TicketList
                    successLogin={login}
                    completeLogin={() => setLogin(false)}
                />
            ),
            needsAuthentication: true,
            breadcrump: [{ label: "Tickets", href: "/#" }],
        },
        {
            path: "/tickets/create",
            name: t("menu.ticketAnlegen"),
            component: <TicketCreate />,
            needsAuthentication: true,
            breadcrump: [
                { label: "Tickets", href: "/tickets" },
                { label: "Ticket anlegen", href: "#" },
            ],
        },
    ];

    return (
        <div className="App">
            <Routes>
                {routes.map((route) => {
                    return (
                        (!route.needsAuthentication || authenticated) && (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    <>
                                        <Layout
                                            notAuthenticated={!authenticated}
                                            breadcrump={route.breadcrump}
                                            onLogout={() =>
                                                setAuthenticated(false)
                                            }
                                        >
                                            {route.component}
                                        </Layout>
                                    </>
                                }
                            ></Route>
                        )
                    );
                })}
                <Route
                    path="/"
                    element={
                        authenticated ? (
                            <Navigate replace to="/tickets" />
                        ) : (
                            <Navigate replace to="/login" />
                        )
                    }
                ></Route>
                <Route
                    path="*"
                    element={
                        <div>
                            404
                            <br />
                            <NavLink to="/">HIER ZUR START</NavLink>
                        </div>
                    }
                ></Route>
            </Routes>
        </div>
    );
}

export default App;
