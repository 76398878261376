import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Ripple } from "primereact/ripple";
import { useTranslation } from "react-i18next";

type Props = {};

const Footer = (props: Props) => {
    const { t } = useTranslation();
    const [displayContent, setDisplayContent] = useState(false);
    const [contentIndex, setContentIndex] = useState(0);

    const [dialogContent, setDialogContent] = useState([
        <>
            <h1>Impressum</h1>
            <p>
                Modern Solution GmbH & Co. KG
                <br />
                Marienstr. 39B
                <br />
                45896 Gladbeck Deutschland
            </p>
            <p>
                Tel.: 02043-9499180
                <br />
                Fax: 02043-9499181
                <br />
                E-Mail: service@moso-connect.de
            </p>
            <p>
                Registergericht: Amtsgericht Gelsenkirchen
                <br />
                Registernummer: HRA 5929
            </p>
            <p>
                Vertretungsberechtigte Gesellschafterin (Komplementär): Modern
                Solution Verwaltungs GmbH, Registergericht: Amtsgericht
                Gelsenkirchen, HRB 15438. Diese wiederum wird vertreten durch
                den Geschäftsführer: Timo Tyrakowski & René Grünhagen
            </p>
            <p>Umsatzsteuer-Identifikationsnummer: DE314531314</p>
            <p>
                Mitglied der Initiative "Fairness im Handel".
                <br />
                Nähere Informationen: https://www.fairness-im-handel.de
            </p>
        </>,
        <>
            <h1>Datenschutzerklärung</h1>
            <h2>
                1) Information über die Erhebung personenbezogener Daten und
                Kontaktdaten des Verantwortlichen
            </h2>
            <p>
                <b>1.1</b>Wir freuen uns, dass Sie unsere Website besuchen und
                bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie
                über den Umgang mit Ihren personenbezogenen Daten bei der
                Nutzung unserer Website. Personenbezogene Daten sind hierbei
                alle Daten, mit denen Sie persönlich identifiziert werden
                können.
            </p>
            <p>
                <b>1.2</b>Verantwortlicher für die Datenverarbeitung auf dieser
                Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist
                Modern Solution GmbH & Co. KG, Marienstr. 39B, 45896 Gladbeck,
                Deutschland, Tel.: 02043-9499180, Fax: 02043-9499181, E-Mail:
                service@moso-connect.de. Der für die Verarbeitung von
                personenbezogenen Daten Verantwortliche ist diejenige natürliche
                oder juristische Person, die allein oder gemeinsam mit anderen
                über die Zwecke und Mittel der Verarbeitung von
                personenbezogenen Daten entscheidet.
            </p>
            <p>
                <b>1.3</b>Der Verantwortliche hat einen Datenschutzbeauftragten
                bestellt, dieser ist wie folgt zu erreichen: "PROLIANCE GmbH
                (Dominik Fünkner) www.datenschutzexperte.de Leopoldstr. 21 80802
                München"
            </p>
            <p>
                <b>1.4</b>Diese Website nutzt aus Sicherheitsgründen und zum
                Schutz der Übertragung personenbezogener Daten und anderer
                vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den
                Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können
                eine verschlüsselte Verbindung an der Zeichenfolge „https://“
                und dem Schloss-Symbol in Ihrer Browserzeile erkennen.
            </p>
            <h2>2) Datenerfassung beim Besuch unserer Website</h2>
            <p>
                Bei der bloß informatorischen Nutzung unserer Website, also wenn
                Sie sich nicht registrieren oder uns anderweitig Informationen
                übermitteln, erheben wir nur solche Daten, die Ihr Browser an
                unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie
                unsere Website aufrufen, erheben wir die folgenden Daten, die
                für uns technisch erforderlich sind, um Ihnen die Website
                anzuzeigen:
            </p>
            <ul>
                <li>Unsere besuchte Website</li>
                <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
                <li>Menge der gesendeten Daten in Byte</li>
                <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
                <li>Verwendeter Browser</li>
                <li>Verwendetes Betriebssystem</li>
                <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
            </ul>
            <p>
                Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf
                Basis unseres berechtigten Interesses an der Verbesserung der
                Stabilität und Funktionalität unserer Website. Eine Weitergabe
                oder anderweitige Verwendung der Daten findet nicht statt. Wir
                behalten uns allerdings vor, die Server-Logfiles nachträglich zu
                überprüfen, sollten konkrete Anhaltspunkte auf eine
                rechtswidrige Nutzung hinweisen.
            </p>
            <h2>3) Cookies</h2>
            <p>
                Um den Besuch unserer Website attraktiv zu gestalten und die
                Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
                Cookies, also kleine Textdateien, die auf Ihrem Endgerät
                abgelegt werden. Teilweise werden diese Cookies nach Schließen
                des Browsers automatisch wieder gelöscht (sog.
                „Session-Cookies“), teilweise verbleiben diese Cookies länger
                auf Ihrem Endgerät und ermöglichen das Speichern von
                Seiteneinstellungen (sog. „persistente Cookies“). Im letzteren
                Fall können Sie die Speicherdauer der Übersicht zu den
                Cookie-Einstellungen Ihres Webbrowsers entnehmen.
                <br />
                Sofern durch einzelne von uns eingesetzte Cookies auch
                personenbezogene Daten verarbeitet werden, erfolgt die
                Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO entweder zur
                Durchführung des Vertrages, gemäß Art. 6 Abs. 1 lit. a DSGVO im
                Falle einer erteilten Einwilligung oder gemäß Art. 6 Abs. 1 lit.
                f DSGVO zur Wahrung unserer berechtigten Interessen an der
                bestmöglichen Funktionalität der Website sowie einer
                kundenfreundlichen und effektiven Ausgestaltung des
                Seitenbesuchs.
                <br />
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                von Cookies informiert werden und einzeln über deren Annahme
                entscheiden oder die Annahme von Cookies für bestimmte Fälle
                oder generell ausschließen können.
                <br />
                Bitte beachten Sie, dass bei Nichtannahme von Cookies die
                Funktionalität unserer Website eingeschränkt sein kann.
            </p>
            <h2>4) Kontaktaufnahme</h2>
            <p>
                <b>4.1</b>- HubSpot
                <br />
                Auf dieser Website werden mit Technologien der HubSpot Ireland
                Ltd., 2nd Floor 30 North Wall Quay, Dublin 1, Irland.
                („HubSpot“), anonymisierte Daten zum Zweck der Webanalyse und
                zum Betreiben des Live-Chat-Systems, das der Beantwortung von
                Live-Support-Anfragen dient, gesammelt und gespeichert. Aus
                diesen anonymisierten Daten können unter einem Pseudonym
                Nutzungsprofile erstellt werden. Hierzu können Cookies
                eingesetzt werden. Bei Cookies handelt es sich um kleine
                Textdateien, die lokal im Zwischenspeicher des Internet-Browsers
                des Seitenbesuchers gespeichert werden. Die Cookies ermöglichen
                die Wiedererkennung des Internet-Browsers. Sofern die so
                erhobenen Informationen einen Personenbezug aufweisen, erfolgt
                die Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
                unseres berechtigten Interesses an effektiver Kundenbetreuung
                und der statistischen Analyse des Nutzerverhaltens zu
                Optimierungszwecken.
                <br />
                Die mit den HubSpot-Technologien erhobenen Daten werden ohne die
                gesondert erteilte Zustimmung des Betroffenen nicht dazu
                benutzt, den Besucher dieser Website persönlich zu
                identifizieren und nicht mit personenbezogenen Daten über den
                Träger des Pseudonyms zusammengeführt. Um die Speicherung von
                HubSpot-Cookies zu vermeiden, können Sie Ihren Internetbrowser
                so einstellen, dass zukünftig keine Cookies mehr auf Ihrem
                Computer abgelegt werden können bzw. bereits abgelegte Cookies
                gelöscht werden. Das Abschalten sämtlicher Cookies kann jedoch
                dazu führen, dass einige Funktionen auf unseren Internetseiten
                nicht mehr ausgeführt werden können. Der Datenerhebung und
                –speicherung zum Zwecke der Erstellung eines pseudonymisierten
                Nutzungsprofils können Sie jederzeit mit Wirkung für die Zukunft
                widersprechen, indem Sie uns Ihren Widerspruch formlos per
                E-Mail an die im Impressum genannte E-Mail-Adresse schicken.
                <br />
                Wir haben mit HubSpot einen Auftragsverarbeitungsvertrag
                abgeschlossen, mit dem wir den Anbieter dazu verpflichten, die
                Daten unserer Kunden zu schützen und sie nicht an Dritte
                weiterzugeben.
                <br />
                Die Datenschutzbestimmungen von HubSpot können Sie hier
                einsehen:{" "}
                <a
                    href="https://legal.hubspot.com/privacy-policy"
                    target="_blank"
                >
                    https://legal.hubspot.com
                    <wbr />
                    /privacy-policy
                </a>
            </p>
            <p>
                <b>4.2</b>&nbsp;Im Rahmen der Kontaktaufnahme mit uns (z.B. per
                Kontaktformular oder E-Mail) werden – ausschließlich zum Zweck
                der Bearbeitung und Beantwortung Ihres Anliegens und nur im
                dafür erforderlichen Umfang – personenbezogene Daten
                verarbeitet. Rechtsgrundlage für die Verarbeitung dieser Daten
                ist unser berechtigtes Interesse an der Beantwortung Ihres
                Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre
                Kontaktierung auf einen Vertrag ab, so ist zusätzliche
                Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
                Ihre Daten werden gelöscht, wenn sich aus den Umständen
                entnehmen lässt, dass der betroffene Sachverhalt abschließend
                geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten
                entgegenstehen
            </p>
            <h2>5) Datenverarbeitung bei Eröffnung eines Kundenkontos</h2>
            <p>
                Gemäß Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten
                im jeweils erforderlichen Umfang weiterhin erhoben und
                verarbeitet, wenn Sie uns diese bei der Eröffnung eines
                Kundenkontos mitteilen. Welche Daten für die Kontoeröffnung
                erforderlich sind, entnehmen Sie der Eingabemaske des
                entsprechenden Formulars auf unserer Website. Eine Löschung
                Ihres Kundenkontos ist jederzeit möglich und kann durch eine
                Nachricht an die o.g. Adresse des Verantwortlichen erfolgen.
                Nach Löschung Ihres Kundenkontos werden Ihre Daten gelöscht,
                sofern alle darüber geschlossenen Verträge vollständig
                abgewickelt sind, keine gesetzlichen Aufbewahrungsfristen
                entgegenstehen und unsererseits kein berechtigtes Interesse an
                der Weiterspeicherung fortbesteht.
            </p>
            <h2>6) Nutzung von Kundendaten zur Direktwerbung</h2>
            <p>
                - Newsletterversand via rapidmail
                <br />
                Der Versand unserer E-Mail-Newsletter erfolgt über den
                technischen Dienstleister rapidmail GmbH, Augustinerplatz 2,
                79098 Freiburg („rapidmail “), an den wir Ihre bei der
                Newsletteranmeldung bereitgestellten Daten weitergeben. Diese
                Weitergabe erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO und dient
                unserem berechtigten Interesse an der Verwendung eines
                werbewirksamen, sicheren und nutzerfreundlichen
                Newslettersystems. Die von Ihnen zwecks Newsletterbezugs
                eingegebenen Daten (z.B. E-Mail-Adresse) werden auf den Servern
                von rapidmail in Deutschland gespeichert.
                <br />
                rapidmail verwendet diese Informationen zum Versand und zur
                statistischen Auswertung der Newsletter in unserem Auftrag. Für
                die Auswertung beinhalten die versendeten E-Mails sog.
                Web-Beacons bzw. Trackings-Pixel, die Ein-Pixel-Bilddateien
                darstellen, die auf unserer Website gespeichert sind. So kann
                festgestellt werden, ob eine Newsletter-Nachricht geöffnet und
                welche Links ggf. angeklickt wurden. Mit Hilfe des sog.
                Conversion-Trackings kann außerdem analysiert werden, ob nach
                Anklicken des Links im Newsletter eine vorab definierte Aktion
                (z.B. Kauf eines Produkts auf unserer Website) erfolgt ist.
                Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt
                des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Die
                Daten werden ausschließlich pseudonymisiert erhoben und werden
                nicht mir Ihren weiteren persönlichen Daten verknüpft, eine
                direkte Personenbeziehbarkeit wird ausgeschlossen. Diese Daten
                dienen ausschließlich der statistischen Analyse von
                Newsletterkampagnen. Die Ergebnisse dieser Analysen können
                genutzt werden, um künftige Newsletter besser an die Interessen
                der Empfänger anzupassen.
                <br />
                Wenn Sie der Datenanalyse zu statistischen Auswertungszwecken
                widersprechen möchten, müssen Sie den Newsletter abbestellen.
                <br />
                Wir haben mit rapidmail einen Auftragsverarbeitungsvertrag
                abgeschlossen, in welchem wir die rapidmail verpflichten, die
                Daten unserer Kunden zu schützen und sie nicht an Dritte
                weiterzugeben.
                <br />
                Weitere Informationen zum Datenschutz von rapidmail können Sie
                in der Datenschutzerklärung von rapidmail nachlesen:{" "}
                <a href="https://www.rapidmail.de/datenschutz" target="_blank">
                    https://www.rapidmail.de
                    <wbr />
                    /datenschutz
                </a>
            </p>
            <h2>7) Datenverarbeitung zur Bestellabwicklung</h2>
            <p>
                Soweit für die Vertragsabwicklung zu Liefer- und Zahlungszwecken
                erforderlich, werden die von uns erhobenen personenbezogenen
                Daten gemäß Art. 6 Abs. 1 lit. b DSGVO an das beauftragte
                Transportunternehmen und das beauftragte Kreditinstitut
                weitergegeben.
            </p>
            <p>
                Sofern wir Ihnen auf Grundlage eines entsprechenden Vertrages
                Aktualisierungen für Waren mit digitalen Elementen oder für
                digitale Produkte schulden, verarbeiten wir die von Ihnen bei
                der Bestellung übermittelten Kontaktdaten (Name, Anschrift,
                Mailadresse), um Sie im Rahmen unserer gesetzlichen
                Informationspflichten gemäß Art. 6 Abs. 1 lit. c DSGVO auf
                geeignetem Kommunikationsweg (etwa postalisch oder per Mail)
                über anstehende Aktualisierungen im gesetzlich vorgesehenen
                Zeitraum persönlich zu informieren. Ihre Kontaktdaten werden
                hierbei streng zweckgebunden für Mitteilungen über von uns
                geschuldete Aktualisierungen verwendet und zu diesem Zweck durch
                uns nur insoweit verarbeitet, wie dies für die jeweilige
                Information erforderlich ist.
            </p>
            <p>
                Zur Abwicklung Ihrer Bestellung arbeiten wir ferner mit dem /
                den nachstehenden Dienstleister(n) zusammen, die uns ganz oder
                teilweise bei der Durchführung geschlossener Verträge
                unterstützen. An diese Dienstleister werden nach Maßgabe der
                folgenden Informationen gewisse personenbezogene Daten
                übermittelt.
            </p>
            <h2>8) Seitenfunktionalitäten</h2>
            <p>Google reCAPTCHA</p>
            <p>
                Auf dieser Website verwenden wir auch die reCAPTCHA Funktion von
                Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04
                E5W5, Irland („Google“). Diese Funktion dient vor allem zur
                Unterscheidung, ob eine Eingabe durch eine natürliche Person
                erfolgt oder missbräuchlich durch maschinelle und automatisierte
                Verarbeitung erfolgt. Der Dienst umfasst den Versand der
                IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA
                benötigter Daten an Google und erfolgt gemäß Art. 6 Abs. 1 lit.
                f DSGVO auf Basis unseres berechtigten Interesses an der
                Feststellung der individuellen Eigenverantwortung im Internet
                und der Vermeidung von Missbrauch und Spam. Im Rahmen der
                Nutzung von Google reCAPTCHA kann es auch zu einer Übermittlung
                von personenbezogenen Daten an die Server der Google LLC. in den
                USA kommen.
            </p>
            <p>
                Weiterführende Informationen zu Google reCAPTCHA sowie die
                Datenschutzerklärung von Google können Sie einsehen unter:{" "}
                <a
                    href="https://www.google.com/intl/de/policies/privacy/"
                    target="_blank"
                >
                    https://www.google.com
                    <wbr />
                    /intl
                    <wbr />
                    /de
                    <wbr />
                    /policies
                    <wbr />
                    /privacy
                    <wbr />/
                </a>
            </p>
            <p>
                Soweit rechtlich erforderlich, haben wir zur vorstehend
                dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gemäß
                Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte
                Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
                Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend
                geschilderte Möglichkeit zur Vornahme eines Widerspruchs.
            </p>
            <h2>9) Tools und Sonstiges</h2>
            <p>Cookie-Consent-Tool</p>
            <p>
                Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen
                für einwilligungspflichtige Cookies und cookie-basierte
                Anwendungen ein sog. „Cookie-Consent-Tool“. Das
                „Cookie-Consent-Tool“ wird Nutzern bei Seitenaufruf in Form
                einer interaktive Benutzeroberfläche angezeigt, auf welcher sich
                per Häkchensetzung Einwilligungen für bestimmte Cookies und/oder
                cookie-basierte Anwendungen erteilen lassen. Hierbei werden
                durch den Einsatz des Tools alle einwilligungspflichtigen
                Cookies/Dienste nur dann geladen, wenn der jeweilige Nutzer
                entsprechende Einwilligungen per Häkchensetzung erteilt. So wird
                sichergestellt, dass nur im Falle einer erteilten Einwilligung
                derartige Cookies auf dem jeweiligen Endgerät des Nutzers
                gesetzt werden.
                <br />
                Das Tool setzt technisch notwendige Cookies, um Ihre
                Cookie-Präferenzen zu speichern. Personenbezogene Nutzerdaten
                werden hierbei grundsätzlich nicht verarbeitet.
                <br />
                Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung
                oder Protokollierung von Cookie-Einstellungen doch zur
                Verarbeitung personenbezogener Daten (wie etwa der IP-Adresse),
                erfolgt diese gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres
                berechtigten Interesses an einem rechtskonformen,
                nutzerspezifischen und nutzerfreundlichen
                Einwilligungsmanagement für Cookies und mithin an einer
                rechtskonformen Ausgestaltung unseres Internetauftritts.
                <br />
                Weitere Rechtsgrundlage für die Verarbeitung ist ferner Art. 6
                Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der
                rechtlichen Verpflichtung, den Einsatz technisch nicht
                notwendiger Cookies von der jeweiligen Nutzereinwilligung
                abhängig zu machen.
                <br />
                Weitere Informationen zum Betreiber und den
                Einstellungsmöglichkeiten des Cookie-Consent-Tools finden Sie
                direkt in der entsprechenden Benutzeroberfläche auf unserer
                Website.
            </p>
            <h2>10) Rechte des Betroffenen</h2>
            <p>
                <b>10.1</b>Das geltende Datenschutzrecht gewährt Ihnen gegenüber
                dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer
                personenbezogenen Daten die nachstehenden Betroffenenrechte
                (Auskunfts- und Interventionsrechte), wobei für die jeweiligen
                Ausübungsvoraussetzungen auf die angeführte Rechtsgrundlage
                verwiesen wird:
            </p>
            <ul>
                <li>Auskunftsrecht gemäß Art. 15 DSGVO;</li>
                <li>Recht auf Berichtigung gemäß Art. 16 DSGVO;</li>
                <li>Recht auf Löschung gemäß Art. 17 DSGVO;</li>
                <li>
                    Recht auf Einschränkung der Verarbeitung gemäß Art. 18
                    DSGVO;
                </li>
                <li>Recht auf Unterrichtung gemäß Art. 19 DSGVO;</li>
                <li>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO;</li>
                <li>
                    Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7
                    Abs. 3 DSGVO;
                </li>
                <li>Recht auf Beschwerde gemäß Art. 77 DSGVO.</li>
            </ul>
            <p>
                <b>10.2</b>&nbsp;WIDERSPRUCHSRECHT
            </p>
            <p>
                WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE
                PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN
                BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE
                RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
                ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR
                DIE ZUKUNFT EINZULEGEN.
                <br />
                MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
                VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG
                BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE
                FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN,
                GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE
                VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                RECHTSANSPRÜCHEN DIENT.
            </p>
            <p>
                WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
                DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
                WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN
                AUSÜBEN.
            </p>
            <p>
                MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
                VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
            </p>
            <h2>11) Dauer der Speicherung personenbezogener Daten</h2>
            <p>
                Die Dauer der Speicherung von personenbezogenen Daten bemisst
                sich anhand der jeweiligen Rechtsgrundlage, am
                Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand
                der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels-
                und steuerrechtliche Aufbewahrungsfristen).
            </p>
            <p>
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage
                einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a
                DSGVO werden diese Daten so lange gespeichert, bis der
                Betroffene seine Einwilligung widerruft.
            </p>
            <p>
                Existieren gesetzliche Aufbewahrungsfristen für Daten, die im
                Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher
                Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO
                verarbeitet werden, werden diese Daten nach Ablauf der
                Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht
                mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
                sind und/oder unsererseits kein berechtigtes Interesse an der
                Weiterspeicherung fortbesteht.
            </p>
            <p>
                Bei der Verarbeitung von personenbezogenen Daten auf Grundlage
                von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange
                gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art.
                21 Abs. 1 DSGVO ausübt, es sei denn, wir können zwingende
                schutzwürdige Gründe für die Verarbeitung nachweisen, die die
                Interessen, Rechte und Freiheiten der betroffenen Person
                überwiegen, oder die Verarbeitung dient der Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>
            <p>
                Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
                Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
                werden diese Daten so lange gespeichert, bis der Betroffene sein
                Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.
            </p>
            <p>
                Sofern sich aus den sonstigen Informationen dieser Erklärung
                über spezifische Verarbeitungssituationen nichts anderes ergibt,
                werden gespeicherte personenbezogene Daten im Übrigen dann
                gelöscht, wenn sie für die Zwecke, für die sie erhoben oder auf
                sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
            </p>
        </>,
    ]);

    return (
        <>
            <div className="surface-section px-4 md:px-6 lg:px-8">
                <div className="surface-section py-6 flex flex-column sm:flex-row align-items-center justify-content-between">
                    <div className="flex flex-nowap font-medium">
                        <a
                            className="p-ripple cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900"
                            href="/"
                        >
                            HOME
                            <Ripple />
                        </a>
                        <span
                            className="p-ripple cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900"
                            onClick={() => {
                                setContentIndex(0);
                                setDisplayContent(true);
                            }}
                        >
                            IMPRESSUM
                            <Ripple />
                        </span>
                        <span
                            className="p-ripple cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900"
                            onClick={() => {
                                setContentIndex(1);
                                setDisplayContent(true);
                            }}
                        >
                            DATENSCHUTZ
                            <Ripple />
                        </span>
                    </div>
                </div>
            </div>
            <Dialog
                className="legals"
                visible={displayContent}
                modal
                style={{ width: "50vw" }}
                position="top"
                onHide={() => {
                    setDisplayContent(false);
                }}
            >
                {dialogContent[contentIndex]}
            </Dialog>
        </>
    );
};

export default Footer;
