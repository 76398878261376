import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

type Props = {
    src: string;
    display: boolean;
    setDisplay: (display: boolean) => void;
};

const TicketPictureZoom = ({ src, display, setDisplay }: Props) => {
    const [width, setWidth] = useState(50);

    useEffect(() => {
        setWidth(50);
    }, [display]);

    const customIcons = () => {
        return (
            <>
                <Button
                    icon="pi pi-search-minus"
                    className="p-button-rounded p-button-text mr-5"
                    onClick={() => {
                        let actualWidth = width;
                        setWidth(actualWidth - 5);
                    }}
                />
                <Button
                    icon="pi pi-search-plus"
                    className="p-button-rounded p-button-text mr-5"
                    onClick={() => {
                        let actualWidth = width;
                        setWidth(actualWidth + 5);
                    }}
                />
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-text mr-5"
                    onClick={() => setDisplay(false)}
                />
            </>
        );
    };

    return (
        <Dialog
            icons={customIcons}
            header=""
            visible={display}
            draggable={false}
            modal
            closable={false}
            onHide={() => {
                setDisplay(false);
            }}
            className="image-modal"
        >
            <div className="align-content-center min-w-screen min-h-screen">
                <img
                    src={src}
                    width={`${width}%`}
                    style={{
                        height: "auto",
                        display: "table",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                    alt="PopupImage"
                />
            </div>
        </Dialog>
    );
};

export default TicketPictureZoom;
