import { AutoComplete } from "primereact/autocomplete";
import React, { useEffect, useState } from "react";
import { patchTicket } from "../../logik/mysqlapicalls";

type Props = {
    initialValues: never[];
    field: string;
    placeholder: string;
    targetKey: string;
    currentKey: string;
    valueKey: any;
    setTempTicketByKey: (key: any, value: any) => void;
    template: (item: any, icon: string, key: string) => JSX.Element;
    icon: string;
    ticket: any;
    changeLog: string;
};

const TicketAutoComplete = ({
    initialValues,
    field,
    placeholder,
    targetKey,
    currentKey,
    valueKey,
    setTempTicketByKey,
    template,
    icon,
    ticket,
    changeLog,
}: Props) => {
    const [value, setValue] = useState("");
    const [possibleValues, setPossibleValues] = useState([]);

    useEffect(() => {
        if (ticket) {
            let currentValue: any = initialValues.filter(
                (initialValue: any) => {
                    return initialValue[currentKey] === ticket[targetKey];
                }
            );
            if (currentValue.length > 0) {
                setValue(currentValue[0][valueKey]);
            }
        } else {
            setValue("");
        }
    }, [initialValues]);

    return (
        <AutoComplete
            className="flex-1"
            dropdown
            delay={1000}
            value={value}
            suggestions={possibleValues}
            completeMethod={(event) => {
                let query = event.query;

                if (query.length > 1 || event.originalEvent.type === "click") {
                    setPossibleValues(
                        [...initialValues].filter((initialValue: any) => {
                            return initialValue[valueKey]
                                .toLowerCase()
                                .includes(query.toLowerCase());
                        })
                    );
                }
            }}
            field={field}
            placeholder={placeholder}
            itemTemplate={(item) => {
                return template(item, icon, valueKey);
            }}
            onChange={(e) => {
                if (typeof e.value === "object") {
                    if (ticket !== null) {
                        let currentValue = initialValues.filter(
                            (initialValue: any) => {
                                return (
                                    initialValue[currentKey] ===
                                    ticket[targetKey]
                                );
                            }
                        );

                        let changeValue: any = {};
                        let changeLogEntry: any = {};

                        changeValue[targetKey] = e.value[currentKey];

                        changeLogEntry = {
                            cText: changeLog
                                .replace(
                                    "#first",
                                    currentValue.length > 0
                                        ? //@ts-ignore
                                          currentValue[0][valueKey]
                                        : "Unbekannt"
                                )
                                .replace("#second", e.value[valueKey]),
                        };

                        patchTicket(ticket, changeValue, changeLogEntry);
                    } else {
                        setTempTicketByKey(targetKey, e.value[currentKey]);
                    }
                    setValue(e.value[valueKey]);
                }
            }}
        />
    );
};

export default TicketAutoComplete;
