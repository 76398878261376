import deTranslation from "./de.json";
import enTranslation from "./en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    de: {
        translation: {
            ...deTranslation,
        },
    },
    en: {
        translation: {
            ...enTranslation,
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: "de",
    lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    // Loads the translations from the json files
});

export default i18n;
