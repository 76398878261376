import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Image } from "primereact/image";

type Props = {
    width: string;
    className: string;
};

const Logo = ({ width, className }: Props) => {
    return <Image src={logo} alt="Image" width={width} className={className} />;
};

export default Logo;
