import { useEffect, useState } from "react";
import { Ticket } from "../../logik/interfaces";

type Props = {
    tickets: Ticket[];
    filterTickets: (filteredTickets: Ticket[]) => void;
    agents: any;
    filterByAgents: boolean;
    currentFilterIndex: number;
    setFilterIndex: (filterIndex: number) => void;
    reloadFilter: boolean;
    setReloadFilter: (reload: boolean) => void;
};

const TicketListFilter = ({
    tickets,
    filterTickets,
    agents,
    filterByAgents,
    currentFilterIndex,
    setFilterIndex,
    reloadFilter,
    setReloadFilter,
}: Props) => {
    const [currentAgents, setCurrentAgents] = useState([]);

    useEffect(() => {
        filterCurrentTickets(currentFilterIndex, "object");
    }, [tickets]);

    useEffect(() => {
        if (reloadFilter) {
            filterCurrentTickets(currentFilterIndex, "object");
        }
    }, [reloadFilter]);

    useEffect(() => {
        let agentIDs: any = [];
        let currentAgents: any = [];

        currentAgents = agents;

        for (const currentAgent of currentAgents) {
            agentIDs.push(currentAgent.kBenutzer);
        }

        setCurrentAgents(agentIDs);
    }, [agents]);

    useEffect(() => {
        console.log(filterByAgents);
        if (filterByAgents) {
            filterCurrentTickets(currentFilterIndex, "object");
        }
    }, [currentAgents]);

    const filterCurrentTickets = (filterIndex: number, type: string) => {
        let filteredTickets: any = [];

        if (filterIndex === 1) {
            filteredTickets = tickets.filter(function (ticket: any) {
                return ticket.ticket_status.fkState === 1;
            });
        } else if (filterIndex === 2) {
            filteredTickets = tickets.filter(function (ticket: any) {
                return ticket.fkUser === 0 || !ticket.fkUser;
            });
        } else if (filterIndex === 3) {
            filteredTickets = tickets.filter(function (ticket: any) {
                return ticket.fkUser === 0 || ticket.fkUser === null;
            });
        } else if (filterIndex === 4) {
            filteredTickets = tickets.filter(function (ticket: any) {
                return (
                    ticket.ticket_status.fkState !== 3 && ticket.fkStatus !== 21
                );
            });
        } else if (filterIndex === 5) {
            filteredTickets = tickets.filter(function (ticket: any) {
                return ticket.fkStatus === 21;
            });
        } else if (filterIndex === 6) {
            filteredTickets = tickets;
        }

        if (type === "count") {
            return filteredTickets.length;
        }

        setFilterIndex(filterIndex);
        setReloadFilter(false);
        filterTickets(
            currentAgents.length > 0
                ? filteredTickets.filter((ticket: Ticket) => {
                      //@ts-ignore
                      return currentAgents.includes(ticket.fkUser);
                  })
                : filteredTickets
        );
        return filteredTickets;
    };

    return tickets.length > 0 ? (
        <>
            <div
                className="col-12 md:col-6 xl:col-2 filterColumn"
                onClick={() => {
                    filterCurrentTickets(1, "object");
                }}
            >
                <div className="border-rounded p-3">
                    <div
                        className="p-3 text-center bg-blue-500"
                        style={{ borderRadius: "12px" }}
                    >
                        <span
                            className="inline-flex justify-content-center align-items-center bg-blue-600 border-circle mb-3"
                            style={{ width: "49px", height: "49px" }}
                        >
                            <i className="pi pi-folder-open text-xl text-white"></i>
                        </span>
                        <div className="text-2xl font-medium text-white mb-2">
                            {filterCurrentTickets(1, "count")}
                        </div>
                        <span className="text-blue-100 font-medium">
                            offene Tickets
                        </span>
                    </div>
                    <div className="flex flex-wrap justify-content-center mt-2">
                        <div
                            className={`border-round w-12rem h-1rem bg-${
                                currentFilterIndex === 1 ? `blue` : `white`
                            }-600`}
                            style={{ maxHeight: "1px" }}
                        ></div>
                    </div>
                </div>
            </div>
            <div
                className="col-12 md:col-6 xl:col-2 filterColumn"
                onClick={() => {
                    filterCurrentTickets(2, "object");
                }}
            >
                <div className="border-rounded p-3">
                    <div
                        className="p-3 text-center bg-yellow-500"
                        style={{ borderRadius: "12px" }}
                    >
                        <span
                            className="inline-flex justify-content-center align-items-center bg-yellow-600 border-circle mb-3"
                            style={{ width: "49px", height: "49px" }}
                        >
                            <i className="pi pi-send text-xl text-white"></i>
                        </span>
                        <div className="text-2xl font-medium text-white mb-2">
                            {filterCurrentTickets(2, "count")}
                        </div>
                        <span className="text-yellow-100 font-medium">
                            ohne Antwort
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-center mt-2">
                    <div
                        className={`border-round w-12rem h-1rem bg-${
                            currentFilterIndex === 2 ? `yellow` : `white`
                        }-600`}
                        style={{ maxHeight: "1px" }}
                    ></div>
                </div>
            </div>
            <div
                className="col-12 md:col-6 xl:col-2 filterColumn"
                onClick={() => {
                    filterCurrentTickets(3, "object");
                }}
            >
                <div className="border-rounded p-3">
                    <div
                        className="p-3 text-center bg-gray-500"
                        style={{ borderRadius: "12px" }}
                    >
                        <span
                            className="inline-flex justify-content-center align-items-center bg-gray-600 border-circle mb-3"
                            style={{ width: "49px", height: "49px" }}
                        >
                            <i className="pi pi-user-minus text-xl text-white"></i>
                        </span>
                        <div className="text-2xl font-medium text-white mb-2">
                            {filterCurrentTickets(3, "count")}
                        </div>
                        <span className="text-gray-100 font-medium">
                            keinem Agenten zugewiesen
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-center mt-2">
                    <div
                        className={`border-round w-12rem h-1rem bg-${
                            currentFilterIndex === 3 ? `gray` : `white`
                        }-600`}
                        style={{ maxHeight: "1px" }}
                    ></div>
                </div>
            </div>
            <div
                className="col-12 md:col-6 xl:col-2 filterColumn"
                onClick={() => {
                    filterCurrentTickets(4, "object");
                }}
            >
                <div className="border-rounded p-3">
                    <div
                        className="p-3 text-center bg-pink-500"
                        style={{ borderRadius: "12px" }}
                    >
                        <span
                            className="inline-flex justify-content-center align-items-center bg-pink-600 border-circle mb-3"
                            style={{ width: "49px", height: "49px" }}
                        >
                            <i className="pi pi-ban text-xl text-white"></i>
                        </span>
                        <div className="text-2xl font-medium text-white mb-2">
                            {filterCurrentTickets(4, "count")}
                        </div>
                        <span className="text-pink-100 font-medium">
                            nicht gelöst
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-center mt-2">
                    <div
                        className={`border-round w-12rem h-1rem bg-${
                            currentFilterIndex === 4 ? `pink` : `white`
                        }-600`}
                        style={{ maxHeight: "1px" }}
                    ></div>
                </div>
            </div>
            {process.env.IS_ELECTRON && (
                <div
                    className="col-12 md:col-6 xl:col-2 filterColumn"
                    onClick={() => {
                        filterCurrentTickets(5, "object");
                    }}
                >
                    <div className="border-rounded p-3">
                        <div
                            className="p-3 text-center bg-green-500"
                            style={{ borderRadius: "12px" }}
                        >
                            <span
                                className="inline-flex justify-content-center align-items-center bg-green-600 border-circle mb-3"
                                style={{ width: "49px", height: "49px" }}
                            >
                                <i className="pi pi-euro text-xl text-white"></i>
                            </span>
                            <div className="text-2xl font-medium text-white mb-2">
                                {filterCurrentTickets(5, "count")}
                            </div>
                            <span className="text-green-100 font-medium">
                                BZA Tickets
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-content-center mt-2">
                        <div
                            className={`border-round w-12rem h-1rem bg-${
                                currentFilterIndex === 5 ? `green` : `white`
                            }-600`}
                            style={{ maxHeight: "1px" }}
                        ></div>
                    </div>
                </div>
            )}
            <div
                className="col-12 md:col-6 xl:col-2 filterColumn"
                onClick={() => {
                    filterCurrentTickets(6, "object");
                }}
            >
                <div className="border-rounded p-3">
                    <div
                        className="p-3 text-center bg-orange-400"
                        style={{ borderRadius: "12px" }}
                    >
                        <span
                            className="inline-flex justify-content-center align-items-center bg-orange-600 border-circle mb-3"
                            style={{ width: "49px", height: "49px" }}
                        >
                            <i className="pi pi-ticket text-xl text-white"></i>
                        </span>
                        <div className="text-2xl font-medium text-white mb-2">
                            {filterCurrentTickets(6, "count")}
                        </div>
                        <span className="text-orange-100 font-medium">
                            Alle Tickets
                        </span>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-center mt-2">
                    <div
                        className={`border-round w-12rem h-1rem bg-${
                            currentFilterIndex === 6 ? `orange` : `white`
                        }-600`}
                        style={{ maxHeight: "1px" }}
                    ></div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};

export default TicketListFilter;
