import { Ticket } from "../../logik/interfaces";
import { useEffect, useRef, useState } from "react";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { setTicketMessage } from "../../logik/mysqlapicalls";

type Props = {
    ticket: Ticket;
    isNote: boolean;
    note: string;
    answer: string;
    draftText: (text: string, isNote: boolean) => void;
    toggleSidebar: (show: boolean) => void;
    showToast: (
        severity: string,
        summary: string,
        detail: string,
        life: number
    ) => void;
};

const TicketAnswering = ({
    ticket,
    isNote,
    note,
    answer,
    draftText,
    toggleSidebar,
    showToast,
}: Props) => {
    const [buttonDisabled, setButtonDiasabled]: any = useState(true);

    useEffect(() => {
        if (isNote) {
            if (note && note.length > 20) {
                setButtonDiasabled(false);
            } else {
                setButtonDiasabled(true);
            }
        } else {
            if (answer && answer.length > 20) {
                setButtonDiasabled(false);
            } else {
                setButtonDiasabled(true);
            }
        }
    }, [note, answer]);

    const saveMessage = async () => {
        let message = await setTicketMessage(
            ticket.kTicket,
            ticket.oContact[0].cMail,
            "",
            "",
            ticket.cTopic,
            isNote ? note : answer,
            isNote
        );

        if (process.env.IS_ELECTRON) {
            if (message.status && message.status !== 200) {
                showToast(
                    "warn",
                    "Probleme beim Mailversand",
                    `Fehlermeldung laut Mailprovider: ${message.data.error.message}`,
                    8000
                );

                toggleSidebar(false);
                draftText("", isNote);
            } else {
                showToast(
                    "success",
                    "Nachricht erfolgreich gespeichert",
                    "",
                    8000
                );
                toggleSidebar(false);
                draftText("", isNote);
            }
        } else {
            showToast("success", "Nachricht erfolgreich gespeichert", "", 8000);
            toggleSidebar(false);
            draftText("", isNote);
        }
    };

    return (
        <div>
            <h3>{isNote ? "Deine Notiz" : "Deine Antwort"}</h3>
            <Editor
                style={{ height: "275px" }}
                value={isNote ? note : answer}
                onTextChange={(e: any) => {
                    draftText(e.htmlValue, isNote);
                }}
            />
            <Button
                label={isNote ? "Speichern" : "Senden"}
                icon={`pi ${isNote ? "pi-save" : "pi-send"}`}
                className="mt-3"
                disabled={buttonDisabled}
                onClick={saveMessage}
            />
        </div>
    );
};

export default TicketAnswering;
