import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { getTickets, getTicketsByExpression } from "../logik/mysqlapicalls";
import TicketContent from "../components/TicketContent";
import TicketListElement from "../components/ticketList/TicketListElement";
import { Ticket } from "../logik/interfaces";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import TicketListPaginator from "../components/ticketList/TicketListPaginator";
import TicketListFilter from "../components/ticketList/TicketListFilter";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { getAgents } from "../logik/apicalls";

type Props = {
    successLogin: boolean;
    completeLogin: () => void;
};

const TicketList = ({ successLogin, completeLogin }: Props) => {
    const { t } = useTranslation();
    const toast = useRef(null);
    const [reload, setReload] = useState(true);
    const [globalSearch, setGlobalSearch] = useState("");
    const [globalSearchIsSearching, setGlobalSearchIsSearching] =
        useState(false);
    const [globalSearchResult, setGlobalSearchResult] = useState([]);
    const [globalConsiderClosed, setGlobalConsiderClosed] = useState(false);
    const [paginatorFirst, setPaginatorFirst] = useState(0);
    const [paginatorRows, setPaginatorRows] = useState(20);
    const [paginatorCount, setPaginatorCount] = useState(0);
    const [agents, setAgents] = useState([]);
    const [systemAgents, setSystemAgents] = useState([]);
    const [tickets, setTickets]: any = useState([]);
    const [filteredTickets, setFilteredTickets]: any = useState([]);
    const [reloadFilter, setReloadFilter]: any = useState(true);
    const [currentFilterIndex, setCurrentFilterIndex]: any = useState(1);
    const [filterByAgents, setFilterByAgents]: any = useState(true);
    const [currentTicket, setCurrentTicket]: any = useState(null);
    const [tabIndex, setTabIndex]: any = useState(0);

    const [storageTicketsystemTabs, setStorageTicketsystemTabs]: any = useState(
        window.electron.store.get("ticketsystem.tabs")
            ? window.electron.store.get("ticketsystem.tabs")
            : [
                  {
                      label: "Liste",
                      iconLeft: "pi-list",
                      iconRight: null,
                      kTicket: null,
                  },
              ]
    );

    useEffect(() => {
        if (reload) {
            getAPIAgents();
            getAPITickets();
        }
    }, [reload]);

    useEffect(() => {
        if (reload) {
            getAPIAgents();
            getAPITickets();
        }
    }, [tabIndex]);

    useEffect(() => {
        let possibleTickets = [];

        possibleTickets = tickets.filter((ticket: Ticket) => {
            return globalConsiderClosed
                ? //@ts-ignore
                  globalSearchResult.includes(ticket.kTicket)
                : //@ts-ignore
                  globalSearchResult.includes(ticket.kTicket) &&
                      ticket.fkStatus != 5 &&
                      ticket.fkStatus != 2;
        });

        if (globalSearchIsSearching) {
            if (possibleTickets.length <= 0) {
                //@ts-ignore
                toast.current.show({
                    severity: "error",
                    summary: "Keine Ergebnisse",
                    detail: "Ihre Suche lieferte leider keine Ergebnisse",
                    life: 3000,
                });
            } else {
                setFilterByAgents(false);
                setAgents([]);
                filterTickets(possibleTickets);
            }
        }

        setGlobalSearchIsSearching(false);
    }, [globalSearchResult]);

    const getAPIAgents = async () => {
        let agents = await getAgents();
        setSystemAgents(agents);
    };

    const getAPITickets = async () => {
        let tickets = await getTickets();
        setTickets(tickets);

        setCurrentTicket(null);
        setReload(false);
    };

    const filterTickets = (filteredTickets: Ticket[]) => {
        setPaginatorCount(filteredTickets.length);
        setFilteredTickets(filteredTickets);
    };

    const onPageChange = (event: any) => {
        setPaginatorFirst(event.first);
        setPaginatorRows(event.rows);
    };

    const setCurrentTicketOnClick = (ticket: Ticket) => {
        setCurrentTicket(ticket.kTicket);
    };

    const setTicketInNewTab = (ticket: Ticket) => {
        let tabIndex = storageTicketsystemTabs.findIndex((tab: any) => {
            return tab.kTicket === ticket.kTicket;
        });

        if (tabIndex === -1) {
            let currentTabs = [...storageTicketsystemTabs];

            currentTabs.push({
                label: `[#${ticket.kTicket}]`,
                iconLeft: "pi-ticket",
                iconRight: "pi-times",
                kTicket: ticket.kTicket,
            });

            window.electron.store.set("ticketsystem.tabs", currentTabs);
            setStorageTicketsystemTabs(currentTabs);

            setTabIndex(currentTabs.length - 1);
        } else {
            setTabIndex(tabIndex);
        }
    };

    const skelletonTemplate = () => {
        let skelletons = [];
        for (let i = 0; i <= 20; i++) {
            skelletons.push(
                <div className="col-12 md:col-6 xl:col-3 p-3">
                    <div className="surface-card shadow-2 border-rounded p-3">
                        <div className="flex border-bottom-1 surface-border pb-3">
                            <Skeleton
                                shape="circle"
                                size="3rem"
                                className="mr-2"
                            ></Skeleton>
                            <div className="flex flex-column justify-content-between">
                                <Skeleton
                                    width="20rem"
                                    className="mt-2 mb-2"
                                ></Skeleton>
                                <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                ></Skeleton>
                                <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                ></Skeleton>
                                <Skeleton
                                    width="20rem"
                                    className="mb-2"
                                ></Skeleton>
                                <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                ></Skeleton>
                                <Skeleton width="10rem"></Skeleton>
                            </div>
                        </div>
                        <div className="flex justify-content-between pt-3">
                            <Skeleton height="29px"></Skeleton>
                        </div>
                    </div>
                </div>
            );
        }

        return skelletons;
    };

    return (
        <>
            <div className="flex bg-white px-2">
                {storageTicketsystemTabs.map((tab: any, index: number) => {
                    return (
                        <div
                            className={`flex-none border-bottom-1 mt-4 mx-2${
                                index === tabIndex
                                    ? ` border-primary-500`
                                    : ` border-white`
                            }`}
                        >
                            <div>
                                <Button
                                    className={`p-3 bg-white border-0${
                                        index === tabIndex
                                            ? ` text-primary-500`
                                            : ` text-500`
                                    }`}
                                    aria-label={tab.label}
                                    style={{ boxShadow: "none" }}
                                    onClick={() => setTabIndex(index)}
                                >
                                    <i
                                        className={`pi mt-1 ${tab.iconLeft}`}
                                    ></i>
                                    <span className="pl-1 pr-3">
                                        {tab.label}
                                    </span>
                                </Button>
                                {tab.iconRight ? (
                                    <i
                                        className={`pi ${tab.iconRight} cursor-pointer text-500`}
                                        style={{
                                            paddingBottom: "11.55px",
                                            paddingRight: "8px",
                                        }}
                                        onClick={() => {
                                            let currentTabs = [
                                                ...storageTicketsystemTabs,
                                            ];
                                            currentTabs.splice(index, 1);

                                            window.electron.store.set(
                                                "ticketsystem.tabs",
                                                currentTabs
                                            );
                                            setStorageTicketsystemTabs(
                                                currentTabs
                                            );

                                            setTabIndex(
                                                index === tabIndex
                                                    ? index - 1
                                                    : index > tabIndex
                                                    ? tabIndex
                                                    : index < tabIndex
                                                    ? tabIndex - 1
                                                    : 0
                                            );
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {tabIndex === 0 ? (
                <div className="grid grid-nogutter ticketList bg-white">
                    <TicketListFilter
                        tickets={tickets}
                        filterTickets={filterTickets}
                        agents={agents}
                        filterByAgents={filterByAgents}
                        currentFilterIndex={currentFilterIndex}
                        setFilterIndex={setCurrentFilterIndex}
                        reloadFilter={reloadFilter}
                        setReloadFilter={setReloadFilter}
                    />
                    <div className="col-12">
                        <div className="grid mx-3 mt-1 bg-white">
                            <div className="col-3">
                                <Button
                                    icon="pi pi-sync"
                                    className="p-button-rounded p-button-outlined mr-2"
                                    aria-label="Reload"
                                    onClick={() => {
                                        setReload(true);
                                    }}
                                />
                                <MultiSelect
                                    value={agents}
                                    options={systemAgents}
                                    onChange={(e: any) => {
                                        setFilterByAgents(true);
                                        setAgents(e.value);
                                    }}
                                    optionLabel="cName"
                                    placeholder="Agent wählen"
                                    display="chip"
                                />
                            </div>
                            <div className="col-6 align-content-center">
                                <TicketListPaginator
                                    paginatorCount={paginatorCount}
                                    paginatorFirst={paginatorFirst}
                                    paginatorRows={paginatorRows}
                                    onPageChange={onPageChange}
                                />
                            </div>
                            <div className="col-3 grid">
                                <div className="col-6">
                                    <span
                                        className={
                                            globalSearch === ""
                                                ? "p-input-icon-left"
                                                : `${
                                                      globalSearchIsSearching
                                                          ? "p-input-icon-left "
                                                          : ""
                                                  }p-input-icon-right`
                                        }
                                    >
                                        {globalSearch === "" ? (
                                            <i className="pi pi-search pt-1" />
                                        ) : (
                                            <></>
                                        )}
                                        {globalSearchIsSearching ? (
                                            <i className="pi pi-spin pi-cog pt-1" />
                                        ) : (
                                            <></>
                                        )}
                                        <InputText
                                            value={globalSearch}
                                            onKeyDown={async (e) => {
                                                if (
                                                    e.key === "Enter" &&
                                                    globalSearch !== ""
                                                ) {
                                                    setGlobalSearchIsSearching(
                                                        true
                                                    );
                                                    setGlobalSearchResult(
                                                        await getTicketsByExpression(
                                                            globalSearch
                                                        )
                                                    );
                                                }
                                            }}
                                            onChange={(e) =>
                                                setGlobalSearch(e.target.value)
                                            }
                                            placeholder="Suche"
                                            className="mt-2"
                                        />
                                        {globalSearch !== "" ? (
                                            <i
                                                className="pi pi-times pt-1"
                                                onClick={() => {
                                                    setFilterByAgents(true);
                                                    setReloadFilter(true);
                                                    setGlobalSearch("");
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="mr-3">
                                        Geschlossene berücksichtigen:
                                        <br />
                                        <InputSwitch
                                            checked={globalConsiderClosed}
                                            onChange={(e) =>
                                                setGlobalConsiderClosed(e.value)
                                            }
                                            className="mr-1"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12"
                        style={{ maxHeight: "800px", overflowY: "auto" }}
                    >
                        <div className="grid mx-0 mt-1">
                            {reload ? (
                                skelletonTemplate()
                            ) : filteredTickets.length <= 0 ? (
                                <div className="text-center col-12">
                                    <div className="grid mx-2 mt-1 bg-white">
                                        <div className="col-12">
                                            <i
                                                className="pi pi-info-circle mr-3"
                                                style={{ fontSize: "5em" }}
                                            ></i>
                                            <h3>
                                                Leider konnenten keine passenden
                                                Tickets gefunden werden
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {filteredTickets
                                        .slice(
                                            paginatorFirst,
                                            paginatorFirst + paginatorRows
                                        )
                                        .map((ticket: Ticket) => {
                                            return (
                                                <TicketListElement
                                                    ticket={ticket}
                                                    setCurrentTicketOnClick={
                                                        setCurrentTicketOnClick
                                                    }
                                                    setTicketInNewTab={
                                                        setTicketInNewTab
                                                    }
                                                />
                                            );
                                        })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <TicketContent
                    kTicket={storageTicketsystemTabs[tabIndex].kTicket}
                    isDialog={false}
                    setReloadParent={setReload}
                    setCurrentTicket={setCurrentTicket}
                ></TicketContent>
            )}
            <TicketContent
                kTicket={currentTicket}
                isDialog={true}
                setReloadParent={setReload}
                setCurrentTicket={setCurrentTicket}
            ></TicketContent>
            <Toast
                //@ts-ignore
                ref={toast}
                position="bottom-right"
            />
        </>
    );
};

export default TicketList;
