import React, { useEffect, useState } from "react";
import { Ticket } from "../../logik/interfaces";

type Props = {
    ticket: Ticket;
};

const TicketAssignment = ({ ticket }: Props) => {
    return <></>;
};

export default TicketAssignment;
