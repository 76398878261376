import { DateTime } from "luxon";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { Ticket } from "../../logik/interfaces";

type Props = {
    ticket: Ticket;
    setCurrentTicketOnClick: (ticket: Ticket) => void;
    setTicketInNewTab: (ticket: Ticket) => void;
};

const TicketListElement = ({
    ticket,
    setCurrentTicketOnClick,
    setTicketInNewTab,
}: Props) => {
    return (
        <div className="col-12 md:col-6 xl:col-3 p-3">
            <div className="surface-card shadow-2 border-rounded p-3">
                <div className="flex border-bottom-1 surface-border pb-3">
                    <Avatar
                        label={
                            ticket.oAgent.length > 0 &&
                            ticket.oAgent[0].cName !== null
                                ? //@ts-ignore
                                  ticket.oAgent[0].cName
                                      .match(/(^\S\S?|\s\S)?/g)
                                      .map((v) => v.trim())
                                      .join("")
                                      .match(/(^\S|\S$)?/g)
                                      .join("")
                                      .toLocaleUpperCase()
                                : "?"
                        }
                        className="mr-3 bg-blue-400 text-50"
                        size="large"
                        onClick={() => setCurrentTicketOnClick(ticket)}
                    />
                    <div className="flex flex-column justify-content-between">
                        <span className="text-lg text-900 font-medium">
                            {ticket.cTopic
                                ? ticket.cTopic.substring(0, 40) +
                                  (ticket.cTopic.length > 40 ? "..." : "")
                                : "ohne Betreff"}
                        </span>
                        <span className="inline-block text-lg text-900 text-pink-500">
                            Ticket:
                            <span className="text-lg text-900 ml-1">
                                {ticket.kTicket}
                            </span>
                        </span>
                        <span className="inline-block text-lg text-900">
                            Status:
                            <span className="text-lg text-900 ml-1">
                                {ticket.ticket_status.cDescriptionIntern}
                            </span>
                        </span>
                        <span className="inline-block text-lg text-900 text-pink-500">
                            Datum:
                            <span className="text-lg text-900 ml-1">
                                {DateTime.fromISO(
                                    // @ts-ignore
                                    ticket.dOpeningDate
                                ).toFormat("dd.MM.yyyy HH:mm:ss")}
                            </span>
                        </span>
                        <span className="text-600 font-medium">
                            {ticket.oCompany.length > 0
                                ? ticket.oCompany[0].cFirma +
                                  (ticket.oCompany[0].cKundenNr !== ""
                                      ? ` (${ticket.oCompany[0].cKundenNr})`
                                      : "")
                                : "Kunde nicht hinterlegt"}
                        </span>
                        <span className="text-600 font-medium">
                            {ticket.oContact.length > 0
                                ? ticket.oContact[0].cVorName +
                                  (ticket.oContact[0].cName !== ""
                                      ? ` ${ticket.oContact[0].cName}`
                                      : "")
                                : "Ansprechpartner nicht hinterlegt"}
                        </span>
                    </div>
                </div>
                <div className="flex justify-content-between pt-3">
                    <Button
                        label="Ticket öffnen"
                        icon="pi pi-external-link"
                        className="p-button-outlined p-button-secondary w-12 mr-2"
                        onClick={() => setCurrentTicketOnClick(ticket)}
                    />
                    <Button
                        label="Ticket in Tab öffnen"
                        icon="pi pi-plus"
                        className="p-button-outlined p-button-secondary w-12 mr-2"
                        onClick={() => setTicketInNewTab(ticket)}
                    />
                </div>
            </div>
        </div>
    );
};

export default TicketListElement;
