import axios from "axios";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { login, sentCode } from "../../logik/apicalls";
import Countdown from "react-countdown";

type Props = {
    onLogin: () => void;
};

const Login = (props: Props) => {
    const toast = useRef(null);
    const [user, setUser] = useState("");
    const [pw, setPw] = useState("");
    const [code, setCode] = useState("");
    const [buttonLabel, setButtonLabel] = useState("Logincode zusenden");
    const [buttonIcon, setButtonIcon] = useState("pi-lock");
    const [codeSent, setCodeSent] = useState(false);
    const [codeTimestamp, setCodeTimestamp] = useState(Date.now);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            navigate("/");
        }
    });

    return (
        <>
            <div
                style={{
                    background:
                        'url("assets/images/blocks/signin/signin-2.jpg") no-repeat',
                    backgroundSize: "cover",
                }}
                className="px-4 py-8 md:px-6 lg:px-8"
            >
                <div className="flex flex-wrap">
                    <div
                        className="w-full md:w-6 bg-no-repeat bg-cover"
                        style={{
                            background:
                                "url('https://www.modernsolution.net/media/image/opc/lg/all_in_one_3.jpg')",
                            minHeight: "500px",
                        }}
                    ></div>
                    <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
                        <div className="text-900 text-2xl font-medium mb-6">
                            {t("ti.login")}
                        </div>
                        {codeSent ? (
                            <>
                                <Countdown
                                    className="mb-3"
                                    date={codeTimestamp + 120000}
                                    renderer={({
                                        hours,
                                        minutes,
                                        seconds,
                                        completed,
                                    }) => {
                                        if (completed) {
                                            // Render a completed state

                                            setButtonLabel(
                                                "Logincode zusenden"
                                            );
                                            setButtonIcon("pi-lock");
                                            setCodeSent(false);

                                            return <></>;
                                        } else {
                                            // Render a countdown
                                            return (
                                                <span>
                                                    {`Security-Code ist noch 
                                                    ${minutes
                                                        .toString()
                                                        .padStart(
                                                            2,
                                                            "0"
                                                        )}:${seconds
                                                        .toString()
                                                        .padStart(2, "0")}
                                                     gültig`}
                                                </span>
                                            );
                                        }
                                    }}
                                />
                                <label
                                    htmlFor="code"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("ti.securityCode")}
                                </label>
                                <InputText
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    id="email3"
                                    type="text"
                                    className="w-full mb-4"
                                />
                            </>
                        ) : (
                            <>
                                <label
                                    htmlFor="email3"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("ti.email")}
                                </label>
                                <InputText
                                    value={user}
                                    onChange={(e) =>
                                        setUser(
                                            e.target.value.replace(/ /g, "")
                                        )
                                    }
                                    id="email3"
                                    type="text"
                                    className="w-full mb-4"
                                />
                                <label
                                    htmlFor="password3"
                                    className="block text-900 font-medium mb-2"
                                >
                                    {t("ti.passwort")}
                                </label>
                                <InputText
                                    value={pw}
                                    onChange={(e) => setPw(e.target.value)}
                                    id="password3"
                                    type="password"
                                    className="w-full mb-4"
                                />
                            </>
                        )}

                        {/* <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            <Checkbox
                                id="rememberme3"
                                className="mr-2"
                                checked={checked3}
                                onChange={(e) => setChecked3(e.checked)}
                            />
                            <label htmlFor="rememberme3"> me</label>
                        </div>
                        <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                            Forgot password?
                        </a>
                    </div> */}

                        <Button
                            label={buttonLabel}
                            icon={`pi ${buttonIcon}`}
                            className="w-full"
                            onClick={async () => {
                                if (
                                    codeSent &&
                                    code !== "" &&
                                    code.length === 6
                                ) {
                                    try {
                                        let res = await login(user, pw, code);

                                        localStorage.setItem(
                                            "token",
                                            res.data.token
                                        );
                                        localStorage.setItem(
                                            "kAnsprechpartner",
                                            res.data.kAnsprechpartner
                                        );
                                        localStorage.setItem(
                                            "kKunde",
                                            res.data.kKunde
                                        );
                                        localStorage.setItem(
                                            "nachname",
                                            res.data.nachname
                                        );
                                        localStorage.setItem(
                                            "vorname",
                                            res.data.vorname
                                        );
                                        localStorage.setItem(
                                            "cEmail",
                                            res.data.cEmail
                                        );
                                        axios.defaults.headers.common.Authorization =
                                            res.data.token;

                                        //@ts-ignore
                                        toast.current.show({
                                            severity: "success",
                                            summary: "Logincode versendet",
                                            detail: `Der Logincode wurde an ${user} versendet`,
                                            life: 6000,
                                        });
                                        props.onLogin();
                                        navigate("/");
                                    } catch (err: any) {
                                        console.log(err.response.status);

                                        //@ts-ignore
                                        toast.current.show({
                                            severity: "error",
                                            summary: "Zugangscode ungültig",
                                            detail: "Der eingegebenen Zugangscode ist ungültig bitte prüfen Sie diesen",
                                            life: 6000,
                                        });
                                    }
                                } else {
                                    if (codeSent) {
                                        if (code === "") {
                                            //@ts-ignore
                                            toast.current.show({
                                                severity: "error",
                                                summary: "Code ist Leer",
                                                detail: "Bitte geben Sie einen validen 6 stelligen Code ein",
                                                life: 6000,
                                            });
                                        } else if (code.length < 6) {
                                            //@ts-ignore
                                            toast.current.show({
                                                severity: "error",
                                                summary: "Code ist zu kurz",
                                                detail: "Bitte geben Sie einen validen 6 stelligen Code ein",
                                                life: 6000,
                                            });
                                        } else if (code.length > 6) {
                                            //@ts-ignore
                                            toast.current.show({
                                                severity: "error",
                                                summary: "Code ist zu lang",
                                                detail: "Bitte geben Sie einen validen 6 stelligen Code ein",
                                                life: 6000,
                                            });
                                        } else {
                                            //@ts-ignore
                                            toast.current.show({
                                                severity: "error",
                                                summary: "Unbekannter Fehler",
                                                detail: "Fehler unbekannt bitte versuchen Sie es später erneut",
                                                life: 6000,
                                            });
                                        }
                                    } else {
                                        try {
                                            await sentCode(user, pw);

                                            setButtonIcon("pi-unlock");
                                            setButtonLabel("Code verifizieren");
                                            setCodeSent(true);
                                            setCodeTimestamp(Date.now);
                                        } catch (err: any) {
                                            console.log(err.response.status);

                                            //@ts-ignore
                                            toast.current.show({
                                                severity: "error",
                                                summary:
                                                    "Zugangsdaten ungültig",
                                                detail: "Die eingegebenen Zugangsdaten sind ungültig bitte prüfen Sie diese",
                                                life: 6000,
                                            });
                                        }
                                    }
                                }
                            }}
                        />

                        {/* <div className="mt-6 text-center text-600">
                        Don't have an account?{" "}
                        <a tabIndex="0" className="font-medium text-blue-500">
                            Sign up
                        </a>
                    </div> */}
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="bottom-right" />
        </>
    );
};

export default Login;
