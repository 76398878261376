import Footer from "./Footer";
import Header from "./Header";

type Props = {
    children: React.ReactNode;
    breadcrump: { label: string; href: string }[];
    notAuthenticated?: boolean;
    onLogout: () => void;
};

const Layout = ({
    children,
    breadcrump,
    notAuthenticated,
    onLogout,
}: Props) => {
    return (
        <div>
            <div className="min-h-screen flex flex-column surface-ground">
                <Header
                    breadcrump={breadcrump}
                    notAuthenticated={notAuthenticated}
                    onLogout={onLogout}
                />
                <div className="p-5 flex flex-column flex-auto">
                    <div className="border-round flex-auto">{children}</div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;
