import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import TicketAutoComplete from "../components/ticketContent/TicketAutoComplete";
import { getCustomers } from "../logik/apicalls";
import { getTicketPriorities, setTicket } from "../logik/mysqlapicalls";

const TicketCreate = () => {
    const { t } = useTranslation();
    const toast = useRef(null);

    const [resetForm, setResetForm] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [topic, setTopic] = useState("");
    const [ccRecipient, setCcRecipient] = useState("");
    const [bccRecipient, setbCCRecipient] = useState("");
    const [editorContent, setEditorContent] = useState("");
    const [tempTicket, setTempTicket] = useState({
        cTopic: null,
        fkPriority: null,
        cText: null,
        cCCRecipients: null,
        cBCCRecipients: null,
    });

    useEffect(() => {
        getAPICustomers();
        getAPIPriorities();
    }, []);

    useEffect(() => {
        let enableSendButton = true;
        for (const key of Object.keys(tempTicket)) {
            if (
                key !== "cCCRecipients" &&
                key !== "cBCCRecipients" &&
                //@ts-ignore
                tempTicket[key] === null
            ) {
                enableSendButton = false;
            }
        }

        if (enableSendButton) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }

        console.log(tempTicket);
    }, [tempTicket]);

    useEffect(() => {
        setTempTicketByKey(
            "cText",
            editorContent === "" ? null : editorContent
        );
    }, [editorContent]);

    useEffect(() => {
        if (resetForm) {
            setTempTicket({
                cTopic: null,
                fkPriority: null,
                cText: null,
                cCCRecipients: null,
                cBCCRecipients: null,
            });
            setTopic("");
            setCcRecipient("");
            setbCCRecipient("");
            setEditorContent("");
            getAPICustomers();
            setResetForm(false);
        }
    }, [resetForm]);

    const getAPICustomers = async () => {
        let customer = await getCustomers(true);
        console.log(customer);
        setCustomer(customer);
    };

    const getAPIPriorities = async () => {
        let priorities = await getTicketPriorities();
        setPriorities(priorities);
    };

    const setTempTicketByKey = (key: any, value: any) => {
        let currentTempTicket: any = { ...tempTicket };

        currentTempTicket[key] = value;

        setTempTicket(currentTempTicket);
    };

    const autoCompleteTemplate = (item: any, icon: string, key: string) => {
        return (
            <div className="agent-item border-bottom-1 surface-border pb-3">
                <div>
                    <span className={`pi pi-${icon} mr-2`}></span>
                    {item[key]}
                </div>
            </div>
        );
    };

    const showToast = (
        severity: string,
        summary: string,
        detail: string,
        life: number
    ) => {
        //@ts-ignore
        toast.current.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: life,
        });
    };

    return (
        <>
            <div className="surface-ground px-4 py-8 md:px-6 lg:px-8 ticketForm">
                <div className="text-900 font-medium text-900 text-xl mb-3">
                    Ticket erstellen
                </div>
                <div className="surface-card p-4 shadow-2 border-round p-fluid">
                    <div className="grid formgrid p-fluid">
                        <div className="field mb-4 col-12 md:col-6">
                            <label
                                htmlFor="customer_name"
                                className="font-medium text-900"
                            >
                                Ansprechpartner
                            </label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-id-card"></i>
                                </span>
                                <InputText
                                    value={`${
                                        "cFirma" in customer
                                            ? //@ts-ignore
                                              `(${customer.cKundenNr}) ${customer.cFirma} -> ${customer.oContacts.cVorName} ${customer.oContacts.cName} [${customer.oContacts.cMail}]`
                                            : ``
                                    }`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label
                                htmlFor="customer_name"
                                className="font-medium text-900"
                            >
                                Priorität
                            </label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-clock"></i>
                                </span>
                                <TicketAutoComplete
                                    initialValues={priorities}
                                    field="priority"
                                    placeholder="Hoch"
                                    targetKey="fkPriority"
                                    currentKey="kPriority"
                                    valueKey="cName"
                                    setTempTicketByKey={setTempTicketByKey}
                                    template={autoCompleteTemplate}
                                    icon="clock"
                                    ticket={null}
                                    changeLog=""
                                />
                            </div>
                        </div>
                        <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                        <div className="field mb-4 col-12">
                            <label
                                htmlFor="notes"
                                className="font-medium text-900"
                            >
                                Betreff:
                            </label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-align-justify"></i>
                                </span>
                                <InputText
                                    placeholder="Anfrage zur Erstellung..."
                                    //@ts-ignore
                                    value={topic}
                                    onChange={(e: any) => {
                                        setTopic(e.target.value);
                                        setTempTicketByKey(
                                            "cTopic",
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label
                                htmlFor="customer_name"
                                className="font-medium text-900"
                            >
                                CC Empfänger
                            </label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-at"></i>
                                </span>
                                <InputText
                                    placeholder="copy@moso.de"
                                    //@ts-ignore
                                    value={ccRecipient}
                                    onChange={(e: any) => {
                                        setCcRecipient(e.target.value);
                                        setTempTicketByKey(
                                            "cCCRecipients",
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="field mb-4 col-12 md:col-6">
                            <label
                                htmlFor="customer_name"
                                className="font-medium text-900"
                            >
                                BCC Empfänger
                            </label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-at"></i>
                                </span>
                                <InputText
                                    //@ts-ignore
                                    value={bccRecipient}
                                    placeholder="blindcopy@moso.de"
                                    onChange={(e: any) => {
                                        setbCCRecipient(e.target.value);
                                        setTempTicketByKey(
                                            "cBCCRecipients",
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                        <div className="field mb-4 col-12">
                            <label
                                htmlFor="notes"
                                className="font-medium text-900"
                            >
                                Informationen:
                            </label>
                            <Editor
                                style={{ height: "275px" }}
                                value={editorContent}
                                onTextChange={(e: any) => {
                                    setEditorContent(e.htmlValue);
                                }}
                            />
                        </div>
                        <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                    </div>
                    <Button
                        label="Ticket anlegen"
                        icon="pi pi-ticket"
                        disabled={!canSend}
                        className="w-auto"
                        onClick={async () => {
                            const ticket = await setTicket(tempTicket);

                            if (ticket.status && ticket.status !== 200) {
                                showToast(
                                    "warn",
                                    "Probleme beim Mailversand",
                                    `Fehlermeldung laut Mailprovider: ${ticket.data.error.message}`,
                                    8000
                                );
                            } else {
                                showToast(
                                    "success",
                                    "Ticket wurde erfolgreich gespeichert",
                                    "",
                                    8000
                                );
                            }
                            setResetForm(true);
                        }}
                    />
                </div>
            </div>
            <Toast ref={toast} position="bottom-right" />
        </>
    );
};

export default TicketCreate;
