import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Ticket } from "../../logik/interfaces";
import { patchTicket } from "../../logik/mysqlapicalls";

type Props = {
    ticket: Ticket;
};

const TicketEditSubject = ({ ticket }: Props) => {
    const [inputValue, setInputValue] = useState("");
    const [subjectSuffix, setSubjectSuffix] = useState("");
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setInputValue(ticket ? ticket.cTopic : "");
        setSubjectSuffix(ticket ? ` [#${ticket.kTicket}]` : "");
    }, [ticket]);

    const saveSubject = () => {
        console.log(inputValue);

        let changeValue: any = {};
        let changeLogEntry: any = {};

        changeValue["cTopic"] = inputValue;

        changeLogEntry = {
            cText: `Der Betreff wurde von "${ticket.cTopic}" zu "${inputValue}" geändert`,
        };

        patchTicket(ticket, changeValue, changeLogEntry);
        setEdit(false);
    };

    return (
        <div
            onDoubleClick={() => {
                setEdit(true);
            }}
        >
            {process.env.IS_ELECTRON && edit ? (
                <span className="p-input-icon-right">
                    <i
                        className="pi pi-save"
                        onClick={() => {
                            saveSubject();
                        }}
                    />
                    <InputText
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                saveSubject();
                            }
                        }}
                    />
                </span>
            ) : (
                <span>{inputValue}</span>
            )}
            {subjectSuffix}
        </div>
    );
};

export default TicketEditSubject;
