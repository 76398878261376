import React, { useRef, useState } from "react";
import { Ripple } from "primereact/ripple";
import { Avatar } from "primereact/avatar";
import { StyleClass } from "primereact/styleclass";
import i18next from "i18next";
import { Badge } from "primereact/badge";
import { NavLink, useNavigate } from "react-router-dom";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import Logo from "../general/Logo";

type Props = {
    notAuthenticated?: boolean;
    breadcrump: { label: string; href: string }[];
    onLogout: () => void;
};

const Header = (props: Props) => {
    const [value, setValue] = useLocalStorage({
        key: "lg",
        defaultValue: "de",
    });

    const menu = useRef(null);
    const { t } = useTranslation();

    const options = [
        {
            value: "de",
            icon: (
                <img
                    src="https://flagcdn.com/de.svg"
                    width="20"
                    alt="Deutschland"
                />
            ),
        },
        {
            value: "en",
            icon: (
                <img
                    src="https://flagcdn.com/gb.svg"
                    width="20"
                    alt="United Kingdom"
                />
            ),
        },
    ];

    const navigate = useNavigate();
    return (
        <>
            <div
                className="surface-overlay px-6 shadow-2 flex justify-content-between relative lg:static"
                style={{ minHeight: "84px" }}
            >
                <Logo
                    width="250px"
                    className="mr-0 lg:mr-6 align-self-center"
                />
                {!props.notAuthenticated && (
                    <>
                        <StyleClass
                            nodeRef={menu}
                            selector="@next"
                            enterClassName="hidden"
                            leaveToClassName="hidden"
                            hideOnOutsideClick
                        >
                            <div
                                ref={menu}
                                className="p-ripple cursor-pointer block lg:hidden align-self-center text-700"
                            >
                                <i className="pi pi-bars text-4xl"></i>
                                <Ripple />
                            </div>
                        </StyleClass>
                        <div className="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                            <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
                                <li
                                    onClick={() => {
                                        navigate("/tickets/create");
                                    }}
                                >
                                    <div className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full">
                                        <i className="pi pi-plus-circle mr-2"></i>
                                        <span>Ticket anlegen</span>
                                        <Ripple />
                                    </div>
                                </li>
                                <li
                                    onClick={() => {
                                        navigate("/tickets");
                                    }}
                                >
                                    <div className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full">
                                        <i className="pi pi-ticket mr-2"></i>
                                        <span>Tickets</span>
                                        <Ripple />
                                    </div>
                                </li>
                            </ul>
                            <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                                <li>
                                    <div className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full">
                                        <MultiStateCheckbox
                                            empty={false}
                                            className="multistatecountry"
                                            value={value}
                                            options={options}
                                            optionValue="value"
                                            onChange={(e) => {
                                                setValue(e.value);
                                                i18next.changeLanguage(e.value);
                                            }}
                                            iconTemplate={(e: any) => {
                                                return e.option.icon;
                                            }}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div
                                        className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full"
                                        onClick={() => {
                                            localStorage.removeItem("token");
                                            localStorage.removeItem(
                                                "kAnsprechpartner"
                                            );
                                            localStorage.removeItem("nachname");
                                            localStorage.removeItem("vorname");
                                            localStorage.removeItem("cEmail");
                                            props.onLogout();
                                            navigate("/login");
                                        }}
                                    >
                                        <i className="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                                            <Badge severity="danger" />
                                        </i>
                                        <span className="block lg:hidden font-medium">
                                            {t("ti.Logout")}
                                        </span>
                                        <Ripple />
                                    </div>
                                </li>
                                <li className="border-top-1 surface-border lg:border-top-none">
                                    <div className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full">
                                        <Avatar
                                            label={
                                                //@ts-ignore
                                                `${localStorage.getItem(
                                                    "vorname"
                                                )} ${localStorage.getItem(
                                                    "nachname"
                                                )}`
                                                    .match(/(^\S\S?|\s\S)?/g)
                                                    .map((v) => v.trim())
                                                    .join("")
                                                    .match(/(^\S|\S$)?/g)
                                                    .join("")
                                                    .toLocaleUpperCase()
                                            }
                                            className="mr-3 surface-400 text-50"
                                            shape="circle"
                                            onClick={() => {}}
                                        />
                                        <Ripple />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                )}
            </div>

            {!props.notAuthenticated && (
                <>
                    <ul className="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
                        <li className="pr-3" onClick={() => navigate("/")}>
                            <span className="cursor-pointer font-medium">
                                <i className="pi pi-home text-500"></i>
                            </span>
                        </li>

                        {props.breadcrump.map((item, index) => (
                            <React.Fragment key={item.label}>
                                <li className="px-2">
                                    <i className="pi pi-angle-right text-500"></i>
                                </li>
                                <li
                                    className="px-2"
                                    onClick={() => navigate("/tickets")}
                                >
                                    <span className="cursor-pointer text-500 white-space-nowrap font-medium">
                                        {item.label}
                                    </span>
                                </li>
                            </React.Fragment>
                        ))}
                    </ul>
                </>
            )}
        </>
    );
};

export default Header;
