import axios from "axios";
import { Message } from "./interfaces";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const getTickets = async () => {
    let response = await axios.get("/tickets/");
    return response.data;
};

export const getTicketsByExpression = async (expression: string) => {
  const params = {
    expression: expression,
  };
  let response = await axios.get(`/tickets/searchtickets`, { params });
  return response.data;
};

export const getTicketsByTicketID = async (kTicket: number | string) => {
  let response = await axios.get(`/tickets/getTicket/${kTicket}`);
  return response.data;
};

export const getTicketHistory = async (ticketID: number) => {
  let response = await axios.get(`/tickets/history/${ticketID}`);
  return response.data;
};

export const getTicketPriorities = async () => {
  let response = await axios.get(`/tickets/priorities`);
  return response.data;
};

export const setTicket = async (ticket: any) => {
  const params = {
    ticket: ticket,
  };
  try {
    let response = await axios.post(`/tickets/setTicket`, params);
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const setTicketMessage = async (
  ticketID: number,
  recipient: string,
  ccrecipient: string,
  bccrecipient: string,
  subject: string,
  message: string,
  isNote: boolean
) => {
  const params = {
    recipient: recipient,
    ccrecipient: ccrecipient,
    bccrecipient: bccrecipient,
    subject: subject,
    message: message,
    isNote: isNote,
  };

  try {
    let response = await axios.post(
      `/ticketssystem/message/${ticketID}`,
      params
    );
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};


export const resendTicketMessage = async (message: Message) => {
  const params = {
    message: message,
  };

  try {
    let response = await axios.post(
      `/tickets/message/resend/${message.kMessage}`,
      params
    );
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const patchTicket = async (ticket: any, patch: any, history: any) => {
  const params = {
    ticket: ticket,
    patch: patch,
    history: history,
  };
  try {
    let response = await axios.patch(
      `/ticketsystem/setTicket/${ticket.kTicket}`,
      params
    );
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};

export const setTicketTimeLog = async (ticket: any, timeLog: any) => {
  const params = {
    ticket: ticket,
    timeLog: timeLog,
  };
  try {
    let response = await axios.post(
      `/ticketsystem/timelog/${ticket.kTicket}`,
      params
    );
    return response.data;
  } catch (error: any) {
    return error.response;
  }
};
