import axios from "axios";

export const getAgents = async () => {
  let response = await axios.get(`/agents`);
  return response.data;
};

export const getCustomers = async (includeContacts: boolean) => {
  const params: any = {
    includeContacts: includeContacts,
  };

  let response = await axios.get(`/customers`, { params });
  return response.data;
};