type Props = {
    ticketID: any;
    ticketTopic: any;
    ticketState: any;
    openingDate: any;
    messages: any;
    jumpToTicket: any;
    setCurrentTicket: (ticketID: number) => void;
};

const TicketBilling = ({
    ticketID,
    ticketTopic,
    ticketState,
    openingDate,
    messages,
    jumpToTicket,
    setCurrentTicket,
}: Props) => {
    return <></>;
};

export default TicketBilling;
