import { MenuEntry, Message, Ticket } from "../../logik/interfaces";
import { useEffect, useRef, useState } from "react";
import { Timeline } from "primereact/timeline";
import { SpeedDial } from "primereact/speeddial";
import { Card } from "primereact/card";
import {
    getTicketHistory,
    resendTicketMessage,
    setTicketTimeLog,
} from "../../logik/mysqlapicalls";
import { Divider } from "primereact/divider";
import { DateTime } from "luxon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Slider } from "primereact/slider";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { InputNumber } from "primereact/inputnumber";
import { Editor } from "primereact/editor";
import { Skeleton } from "primereact/skeleton";
import TicketPictureZoom from "./TicketPictureZoom";

type Props = {
    ticket: Ticket;
    filter: string;
    reload: boolean;
    finishReload: (finish: boolean) => void;
    reloadParent: () => void;
};

const TicketHistory = ({
    ticket,
    filter,
    reload,
    finishReload,
    reloadParent,
}: Props) => {
    const toast = useRef(null);
    const [resetFrom, setResetForm] = useState(false);
    const [showPicture, setShowPicture] = useState(false);
    const [srcPicture, setSrcPicture] = useState("");
    const [ticketHistory, setTicketHistory]: any = useState([]);
    const [filteredTicketHistory, setFilteredTicketHistory]: any = useState([]);
    const [displaySidebar, setDisplaySidebar]: any = useState(false);
    const [timeLog, setTimeLog]: any = useState({
        fkMessage: 0,
        time: 0.25,
        type: "Abrechenbar",
        note: "",
    });
    const [editorHeader, setEditorHeader] = useState(<></>);
    const [time, setTime] = useState(0.25);
    const [type, setType] = useState("Abrechenbar");
    const [note, setNote] = useState("");
    const [save, setSave] = useState(false);
    const options = [
        { value: "Abrechenbar", icon: "pi pi-euro" },
        { value: "Pauschal", icon: "pi pi-chart-pie" },
        { value: "Kontingent", icon: "pi pi-wallet" },
        { value: "Kostenlos", icon: "pi pi-ban" },
    ];

    useEffect(() => {
        setEditorHeader(renderHeader());
    }, []);

    useEffect(() => {
        if (filter) {
            let history = ticketHistory.filter(function (historyEntry: any) {
                return historyEntry.oEntry.cText.includes(filter);
            });
            setFilteredTicketHistory(history);
        } else {
            //console.log(ticketHistory);
            setFilteredTicketHistory(ticketHistory);
        }
    }, [filter]);

    useEffect(() => {
        if (ticket) {
            getTicketHistoryAPI();
        }
    }, [ticket]);

    useEffect(() => {
        if (timeLog.note && timeLog.type && timeLog.time) {
            setSave(true);
        } else {
            setSave(false);
        }
    }, [timeLog]);

    useEffect(() => {
        if (resetFrom) {
            setTimeLog({
                fkMessage: 0,
                time: 0.25,
                type: "Abrechenbar",
                note: "",
            });

            setTime(0.25);
            setType("Abrechenbar");
            setNote("");
            setSave(false);

            getTicketHistoryAPI();
            setEditorHeader(renderHeader());
            setResetForm(false);
        }
    }, [resetFrom]);

    useEffect(() => {
        setTimeLogByKey("note", note === "" ? null : note);
    }, [note]);

    useEffect(() => {
        console.log(srcPicture);

        if (srcPicture && srcPicture.length > 10) {
            setShowPicture(true);
        }
    }, [srcPicture]);

    const getTicketHistoryAPI = async () => {
        let history = [];

        let historyEntries = await getTicketHistory(ticket.kTicket);

        for (const historyEntry of historyEntries) {
            history.push({
                icon: `pi ${
                    "kHistory" in historyEntry
                        ? "pi-paperclip"
                        : `${
                              historyEntry.fkUser === 0
                                  ? `pi-envelope`
                                  : `pi-send`
                          }`
                }`,
                buttonClass:
                    historyEntry.fkUser === 0
                        ? "p-button-info"
                        : "p-button-success",
                menuDirection: historyEntry.fkUser === 0 ? "left" : "right",
                oEntry: historyEntry,
                submenu:
                    "kHistory" in historyEntry
                        ? []
                        : process.env.IS_ELECTRON
                        ? [
                              {
                                  label: "Nachricht erneut senden",
                                  icon: "pi pi-send",
                                  command: async () => {
                                      let mailResponse =
                                          await resendTicketMessage(
                                              historyEntry
                                          );

                                      if (
                                          mailResponse.status &&
                                          mailResponse.status !== 200
                                      ) {
                                          //@ts-ignore
                                          toast.current.show({
                                              severity: "warn",
                                              summary:
                                                  "Probleme beim Mailversand",
                                              detail: `Fehlermeldung laut Mailprovider: ${mailResponse.data.error.message}`,
                                              life: 8000,
                                          });
                                      } else {
                                          //@ts-ignore
                                          toast.current.show({
                                              severity: "success",
                                              summary:
                                                  "Nachricht erfolgreich versendet",
                                              detail: `Die Nachricht wurde erfolgreich an ${historyEntry.oContact[0].cMail} versendet`,
                                              life: 8000,
                                          });
                                      }
                                  },
                              },
                              {
                                  label: "Termin erstellen",
                                  icon: "pi pi-calendar-plus",
                                  command: () => {
                                      //@ts-ignore
                                      toast.current.show({
                                          severity: "warn",
                                          summary: "Aktion nicht vorhanden",
                                          detail: "Aktuell ist diese Aktion noch nicht verfügbar",
                                          life: 3000,
                                      });
                                  },
                              },
                              {
                                  label: "Nachricht taggen",
                                  icon: "pi pi-tags",
                                  command: () => {
                                      //@ts-ignore
                                      toast.current.show({
                                          severity: "warn",
                                          summary: "Aktion nicht vorhanden",
                                          detail: "Aktuell ist diese Aktion noch nicht verfügbar",
                                          life: 3000,
                                      });
                                  },
                              },
                              {
                                  label: "Zeit buchen",
                                  icon: "pi pi-clock",
                                  command: () => {
                                      setTimeLogByKey(
                                          "fkMessage",
                                          historyEntry.kMessage
                                      );
                                      setDisplaySidebar(true);
                                  },
                              },
                          ]
                        : [
                              {
                                  label: "Termin vorschlagen",
                                  icon: "pi pi-calendar-plus",
                                  command: () => {
                                      //@ts-ignore
                                      toast.current.show({
                                          severity: "warn",
                                          summary: "Aktion nicht vorhanden",
                                          detail: "Aktuell ist diese Aktion noch nicht verfügbar",
                                          life: 3000,
                                      });
                                  },
                              },
                          ],
            });
        }

        setFilteredTicketHistory(history);
        setTicketHistory(history);
        finishReload(false);
    };

    const setTimeLogByKey = (key: any, value: any) => {
        let currentTimeLog: any = {
            ...timeLog,
        };
        currentTimeLog[key] = value;
        setTimeLog(currentTimeLog);
    };

    const customizedMarker = (item: MenuEntry) => {
        return "kMessage" in item.oEntry ? (
            <>
                <Tooltip
                    target={`.ticketsystem-history-element-m-${item.oEntry.kMessage} .p-speeddial-action`}
                    position={item.menuDirection}
                />
                <SpeedDial
                    model={item.submenu}
                    radius={55}
                    direction={item.menuDirection}
                    type="semi-circle"
                    className={`ticketsystem-history-element-${item.oEntry.kMessage}`}
                    buttonClassName={`p-3 border-circle p-button-${
                        item.menuDirection === "left" ? `danger` : `success`
                    } text-white ${item.buttonClass}`}
                    showIcon={item.icon}
                    hideIcon="pi pi-times"
                />
            </>
        ) : (
            <Button
                icon={item.icon}
                className={`p-button p-component p-speeddial-button p-button-rounded p-3 border-circle text-white p-button-${
                    item.menuDirection === "left" ? `danger` : `success`
                } p-button-icon-only`}
                aria-label="Historie"
            />
        );
    };

    const customizedContent = (item: MenuEntry) => {
        return item.menuDirection === "right" ? (
            <Card
                title={
                    "kMessage" in item.oEntry
                        ? item.oEntry.cSubject
                        : item.oEntry.cText.replaceAll("<br>", "")
                }
                subTitle={DateTime.fromISO(
                    //@ts-ignore
                    "kMessage" in item.oEntry
                        ? item.oEntry.dCreationDate
                        : item.oEntry.dTimestamp
                )
                    .minus({ hour: 2 })
                    .toFormat("dd.MM.yyyy HH:mm:ss")}
                className="tickethistoryentry"
            >
                {"kMessage" in item.oEntry ? (
                    <div
                        onClick={maximizePicture}
                        dangerouslySetInnerHTML={{ __html: item.oEntry.cText }}
                    ></div>
                ) : (
                    <></>
                )}
                <Divider />
            </Card>
        ) : (
            <></>
        );
    };

    const customizedOppositeContent = (item: MenuEntry) => {
        return item.menuDirection === "left" ? (
            <Card
                title={
                    "kMessage" in item.oEntry
                        ? item.oEntry.cSubject
                        : item.oEntry.cText.replaceAll("<br>", "")
                }
                subTitle={DateTime.fromISO(
                    //@ts-ignore
                    "kMessage" in item.oEntry
                        ? item.oEntry.dCreationDate
                        : item.oEntry.dTimestamp
                )
                    .minus({ hour: 2 })
                    .toFormat("dd.MM.yyyy HH:mm:ss")}
                className="tickethistoryentry"
            >
                {"kMessage" in item.oEntry ? (
                    <div
                        onClick={maximizePicture}
                        dangerouslySetInnerHTML={{ __html: item.oEntry.cText }}
                    ></div>
                ) : (
                    <></>
                )}
                <Divider />
            </Card>
        ) : (
            <></>
        );
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button
                    className="ql-underline"
                    aria-label="Underline"
                ></button>
                <button
                    type="button"
                    className="ql-link"
                    aria-label="Insert Link"
                ></button>
                <button
                    type="button"
                    className="ql-image"
                    aria-label="Insert Image"
                ></button>
                <button
                    type="button"
                    className="ql-code-block"
                    aria-label="Insert Code Block"
                ></button>
            </span>
        );
    };

    const maximizePicture = (event: any) => {
        setSrcPicture(event.target.src);
        setShowPicture(true);
    };

    return reload ? (
        <div className="text-center">
            <Timeline
                value={[
                    {
                        position: "right",
                        content: (
                            <Card
                                title={
                                    <Skeleton
                                        width="30rem"
                                        height="2rem"
                                        borderRadius="2px"
                                        className="mb-3"
                                    />
                                }
                                subTitle={
                                    <Skeleton width="10rem" className="mb-2" />
                                }
                                className="tickethistoryentry"
                            >
                                <Skeleton
                                    width="35rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="25rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="30rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="20rem"
                                    height="10em"
                                    className="mb-2 mt-2"
                                    borderRadius="2px"
                                />
                                <Skeleton
                                    width="30rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="22rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                            </Card>
                        ),
                    },
                    {
                        position: "left",
                        content: (
                            <Card
                                title={
                                    <Skeleton
                                        width="30rem"
                                        height="2rem"
                                        borderRadius="2px"
                                        className="mb-3"
                                    />
                                }
                                subTitle={
                                    <Skeleton width="10rem" className="mb-2" />
                                }
                                className="tickethistoryentry"
                            >
                                <Skeleton
                                    width="35rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="25rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="30rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="20rem"
                                    height="10em"
                                    className="mb-2 mt-2"
                                    borderRadius="2px"
                                />
                                <Skeleton
                                    width="30rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                                <Skeleton
                                    width="22rem"
                                    className="mb-2"
                                    borderRadius="16px"
                                />
                            </Card>
                        ),
                    },
                ]}
                marker={
                    <Skeleton shape="circle" size="4rem" className="mr-2" />
                }
                content={(item: any) => {
                    return item.position === "right" ? item.content : <></>;
                }}
                opposite={(item: any) => {
                    return item.position === "left" ? item.content : <></>;
                }}
                className="mt-5"
                align="left"
            />
        </div>
    ) : (
        <>
            <Timeline
                value={filteredTicketHistory}
                marker={customizedMarker}
                content={customizedContent}
                opposite={customizedOppositeContent}
                className="mt-5"
                align="left"
            />
            <TicketPictureZoom
                display={showPicture}
                src={srcPicture}
                setDisplay={setShowPicture}
            />
            <Toast ref={toast} />
            {process.env.IS_ELECTRON && (
                <Sidebar
                    dismissable={false}
                    visible={displaySidebar}
                    position="right"
                    onHide={() => setDisplaySidebar(false)}
                    className="w-2"
                >
                    <div className="grid">
                        <div className="col-12 pb-5">
                            <h3>Zeit buchen</h3>
                        </div>
                        <div className="col-3 pb-5">Zeit:</div>
                        <div className="col-9 pb-5">
                            <Slider
                                value={time}
                                onChange={(e: any) => {
                                    setTimeLogByKey("time", e.value);
                                    setTime(e.value);
                                }}
                                step={0.25}
                                min={0.25}
                                max={20}
                            />
                        </div>
                        <div className="col-3 pb-5">Zeit:</div>
                        <div className="col-9 pb-5">
                            <InputNumber
                                value={time}
                                onChange={(e: any) => {
                                    setTimeLogByKey("time", e.value);
                                    setTime(e.value);
                                }}
                                mode="decimal"
                                minFractionDigits={2}
                                step={0.25}
                                min={0.25}
                                max={20}
                                showButtons
                                buttonLayout="horizontal"
                                decrementButtonClassName="p-button-secondary"
                                incrementButtonClassName="p-button-secondary"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                            />
                        </div>
                        <div className="col-3 pb-5">Typ:</div>
                        <div className="col-9 pb-5">
                            <MultiStateCheckbox
                                value={type}
                                options={options}
                                optionValue="value"
                                empty={false}
                                onChange={(e: any) => {
                                    setType(e.value);
                                    setTimeLogByKey("type", e.value);
                                }}
                            />
                            <span className="ml-3">{timeLog.type}</span>
                        </div>
                        <div className="col-12">Tätigkeit:</div>
                        <div className="col-12">
                            <Editor
                                style={{ height: "275px" }}
                                value={note}
                                onTextChange={(e: any) => {
                                    setNote(e.htmlValue);
                                }}
                                headerTemplate={editorHeader}
                            />
                        </div>
                    </div>

                    <Button
                        icon="pi pi-save"
                        label="Speichern"
                        aria-label="Speichern"
                        disabled={!save}
                        onClick={async () => {
                            let timeLogResponse = await setTicketTimeLog(
                                ticket,
                                timeLog
                            );

                            if (
                                timeLogResponse.status &&
                                timeLogResponse.status !== 200
                            ) {
                                //@ts-ignore
                                toast.current.show({
                                    severity: "error",
                                    summary: "Probleme bei Zeitbuchung",
                                    detail: "Zeitbuchung konnte nicht durchgeführt werden",
                                    life: 3000,
                                });
                            } else {
                                //@ts-ignore
                                toast.current.show({
                                    severity: "success",
                                    summary:
                                        "Zeitbuchung erfolgreich gespeichert",
                                    detail: "",
                                    life: 3000,
                                });
                                setTimeLog({
                                    fkMessage: 0,
                                    time: 0.25,
                                    type: "Abrechenbar",
                                    note: "",
                                });
                                setResetForm(true);
                                setDisplaySidebar(false);
                                reloadParent();
                            }
                        }}
                    />
                </Sidebar>
            )}
        </>
    );
};

export default TicketHistory;
